import React from "react";

function ShowAlert({ errorMessage, alertType, setIsError }) {
    const handleClose = () => {
        setIsError(false);
    };
  return (
    <div
      className={`alert alert-${alertType} alert-dismissible fade show`}
      role="alert"
    >
     {errorMessage}
      <button
        onClick={handleClose}
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  );
}

export default ShowAlert;
