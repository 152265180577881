import React, { useCallback, useEffect, useState } from "react";
import instance from "../../axios";
import DashboardCard from "../../components/DashboardCard";
import DashboardSummaryCard from "../../components/DashboardSummaryCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import Spinner from "../../components/Spinner";
import ShowAlert from "../../components/ShowAlert";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const appUrl = process.env.REACT_APP_URL;

  const fetchDashboard = useCallback(async () => {
    setIsLoading(true);
    instance
      .get("/dashboard")
      .then((res) => {
        setDashboardData(res.data.surveys);
        setSummaryData(res.data.summary);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const changeStatus = (id) => {
    setIsUpdating(true);
    instance
      .post(`/survey/${id}/status`)
      .then((res) => {
        setIsUpdating(false);
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsUpdating(false);
        setIsError(true);
        console.log(err);
      });
  };

  const copyLinkToClipboard = (slug) => {
    navigator.clipboard.writeText(`${appUrl}/survey/${slug}`);
  };

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isUpdating && <Spinner />}
          {isError && (
            <ShowAlert
              errorMessage="Something went wrong"
              alertType={"danger"}
              setIsError={setIsError}
            />
          )}
          {isSuccess && (
            <ShowAlert
              errorMessage="Status updated successfully"
              alertType={"success"}
              setIsError={setIsSuccess}
            />
          )}
          {/* <div className="flex flex-wrap justify-between items-center my-4">
    {Object.keys(summaryData).map((key, index) => (
      <DashboardSummaryCard
        key={index}
        title={key}
        value={summaryData[key]}
      />
    ))}
  </div> */}
          <div className="flex flex-wrap justify-center sm:justify-between items-center gap-4 sm:my-4 my-4">
            {Object.keys(summaryData).map((key, index) => (
              <DashboardSummaryCard
                key={index}
                title={key}
                value={summaryData[key]}
              />
            ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {dashboardData.map((item, index) => (
              <div key={index}>
                <DashboardCard
                  title={item.title}
                  description={item.description}
                  startDate={item.start_date}
                  endDate={item.end_date}
                  slug={item.slug}
                  isActive={item.is_active}
                  responseCount={item.responses_count}
                  changeStatus={() => changeStatus(item.survey_id)}
                  copyLinkToClipboard={() => copyLinkToClipboard(item.slug)}
                />
              </div>
            ))}
          </div>
        </>

        // <>
        //   {isUpdating && <Spinner />}
        //   {isError && (
        //     <ShowAlert
        //       errorMessage="Something went wrong"
        //       alertType={"danger"}
        //       setIsError={setIsError}
        //     />
        //   )}
        //   {isSuccess && (
        //     <ShowAlert
        //       errorMessage="Status updated successfully"
        //       alertType={"success"}
        //       setIsError={setIsSuccess}
        //     />
        //   )}
        //   <div className="d-flex justify-content-between align-items-center my-4">
        //     {Object.keys(summaryData).map((key, index) => (
        //       <DashboardSummaryCard
        //         key={index}
        //         title={key}
        //         value={summaryData[key]}
        //       />
        //     ))}
        //   </div>
        //   <div className="row">
        //     {dashboardData.map((item, index) => (
        //       <div key={index} className="col-4 my-2">
        //         <DashboardCard
        //           title={item.title}
        //           description={item.description}
        //           startDate={item.start_date}
        //           endDate={item.end_date}
        //           slug={item.slug}
        //           isActive={item.is_active}
        //           responseCount={item.responses_count}
        //           changeStatus={() => changeStatus(item.survey_id)}
        //           copyLinkToClipboard={() => copyLinkToClipboard(item.slug)}
        //         />
        //       </div>
        //     ))}
        //   </div>
        // </>
      )}
    </>
  );
}

export default Dashboard;
