import React, { useRef, useState, useEffect } from "react";
import Text from "../../components/questions/Text";
import Checkbox from "../../components/questions/Checkbox";
import Radio from "../../components/questions/Radio";
import Select from "../../components/questions/Select";
import TextArea from "../../components/questions/TextArea";
import DateInput from "../../components/questions/DateInput";
import NumberInput from "../../components/questions/NumberInput";
import RangeInput from "../../components/questions/RangeInput";
import { v4 as uuid } from "uuid";
import instance from "../../axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Spinner from "../../components/Spinner";
import ShowAlert from "../../components/ShowAlert";
import MultiGrid from "../../components/questions/MultiGrid";
import FormHeader from "../../components/questions/FormHeader";
import FormText from "../../components/questions/FormText";
import KaiserTool from "../../components/questions/KaiserTool";

function CreateSurvey() {
  const [survey, setSurvey] = useState({
    title: "",
    description: "",
    image: "",
    slug: "",
    questions: [],
    startDate: "",
    endDate: "",
    isActive: false,
    isPublic: false,
    isAnonymous: false,
  });
  const editFormRef = useRef(null);
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [optionString, setOptionString] = useState("");
  const [options, setOptions] = useState([]);
  const [is_required, setIsRequired] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [save, setSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  // multi grid
  const [labelString, setLabelString] = useState();
  const [labels, setLabels] = useState([]);
  const [multiGridOptions, setMultiGridOptions] = useState([]);
  const [multiGridOptionString, setMultiGridOptionString] = useState();
  const [multiGridValue, setMultiGridValue] = useState({});
  const handleLabelStrings = (e) => {
    //
  };
  const handleLabels = (e) => {
    setLabels(labelString.split(","));
  };

  const handleMultiGridOptions = (e) => {
    setMultiGridOptions(multiGridOptionString.split(","));
  };

  const handleOptions = (e) => {
    console.log(e.target.value);
    setOptionString(e.target.value);
    // split basing on new line
    const options = e.target.value.split("\n").map((option) => {
      return {
        id: uuid(),
        question: option,
        description: "",
        value: option,
      };
    });
    setOptions(options);
  };

  const [id, setId] = useState(null);
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // set survey
    if (name === "question") {
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        slug: value.replace(/\s+/g, "-").toLowerCase(),
        [name]: value,
      }));
    } else {
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        [name]: value,
      }));
    }
  };

  const handleEditSurvey = (id) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      status: null,
    }));

    editFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case "text":
      case "email":
      case "time":
        return (
          <div className="mb-3">
            <Text
              label={question.question}
              description={question.description}
              type={question.type}
              required={question.is_required}
              disabled={question.disabled}
            />
          </div>
        );
      case "checkbox":
        return (
          <div className="mb-3">
            <label>{question.question}</label>
            <p className="form-text">{question.description}</p>
            <div className="row">
              {question.options.map((option) => (
                <div className="" key={option.id}>
                  <Checkbox
                    label={option.question}
                    description={option.description}
                    type={question.type}
                    required={question.is_required}
                    disabled={question.disabled}
                    value={option.value}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "radio":
        return (
          <div className="mb-3">
            <label>{question.question}</label>
            <p className="form-text">{question.description}</p>
            <div className="row">
              {question.options.map((option) => (
                <div className="" key={option.id}>
                  <Radio
                    label={option.question}
                    description={option.description}
                    type={question.type}
                    required={question.is_required}
                    disabled={question.disabled}
                    value={option.value}
                    name={
                      question.question.replace(/\s+/g, "-").toLowerCase()
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "select":
        return (
          <div className="mb-3">
            <label>{question.question}</label>
            <p className="form-text">{question.description}</p>
            <Select
              label={question.question}
              required={question.is_required}
              disabled={question.disabled}
              options={question.options}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="mb-3">
            <TextArea
              label={question.question}
              description={question.description}
              required={question.is_required}
              disabled={question.disabled}
            />
          </div>
        );
      case "date":
        return (
          <DateInput
            label={question.question}
            description={question.description}
            required={question.is_required}
            disabled={question.disabled}
            type={question.type}
            minDate={question.minDate}
            maxDate={question.maxDate}
          />
        );
      case "number":
        return (
          <NumberInput
            label={question.question}
            description={question.description}
            required={question.is_required}
            disabled={question.disabled}
            type={question.type}
            min={question.min}
            max={question.max}
          />
        );
      case "range":
        return (
          <RangeInput
            label={question.question}
            description={question.description}
            required={question.is_required}
            disabled={question.disabled}
            type={question.type}
            min={question.min}
            max={question.max}
          />
        );
      case "multi-grid":
        return (
          <MultiGrid
            label={question.question}
            headers={question.options.labels}
            options={question.options.options}
            value={multiGridValue}
            setValue={setMultiGridValue}
          />
        );
      case "heading":
        return (
          <FormHeader title={question.question} />
        );
      case "paragraph":
        return (
          <FormText text={question.question} />
        );
      case "kaiser-tool":
        return (
          <div className="mb-3">
            <label className="form-label">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <KaiserTool
              label={question.question}
              headers={question.options.labels}
              options={question.options.options}
              value={multiGridValue}
              setValue={setMultiGridValue}
            />
          </div>
        )
      default:
        return <></>;
    }
  };

  const handleRequiredSurveyFields = () => {
    if (
      survey.title === "" ||
      survey.description === "" ||
      survey.startDate === "" ||
      survey.endDate === ""
    ) {
      return true;
    }
    return false;
  };

  const handleCreateSurvey = (e) => {
    e.preventDefault();
    if (handleRequiredSurveyFields()) {
      return;
    }
    setSave(true);
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      status: "draft",
    }));

    // api call to create survey
    instance
      .post("/survey", survey)
      .then((res) => {
        console.log(res.data);
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          id: res.data.data.survey_id,
          image: res.data.data.image_url,
        }));
        setSave(false);
      })
      .catch((err) => {
        console.log(err);
        setSave(false);
      });
  };

  const handleUpdateSurvey = (e) => {
    e.preventDefault();
    setIsSaving(true);
    // make api call to update survey with questions
    instance
      .post("/survey/questions", {
        questions: survey.questions,
        survey_id: survey.id,
      })
      .then((res) => {
        // update survey questions with id from api
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          questions: res.data.data.questions,
        }));
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
      });
  };

  const requiresOption = (type) => {
    if (type === "checkbox" || type === "radio" || type === "select") {
      return true;
    }
    return false;
  };

  const isMultiGrid = (type) => {
    if (type === "multi-grid" || type === "kaiser-tool") {
      return true;
    }
    return false;
  };

  const isNonInputField = (type) => {
    if (type === "heading" || type === "paragraph") {
      return true;
    }
    return false;
  };

  //   handle add question
  const addQuestion = (e) => {
    e.preventDefault();
    if (
      question === "" ||
      type === "" ||
      (requiresOption(type) && optionString === "")
    ) {
      setIsError(true);
      setErrorMessage("Please fill all required fields");
      return;
    }
    if (id) {
      // update question with id
      // get question with id
      const q = survey.questions.find((question) => question.id === id);
      console.log(q);
      console.log(labels, multiGridOptions);
      // update question
      q.question = question;
      q.description = description;
      q.type = type;
      q.is_required = is_required;
      q.disabled = disabled;
      q.options = type === "multi-grid" ? { labels: labels, options: multiGridOptions } : options;
      console.log(q);
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        questions: prevSurvey.questions.filter((question) =>
          question.id === id ? q : question
        ),
      }));

      setQuestion("");
      setDescription("");
      setType("text");
      setIsRequired(false);
      setDisabled(false);
      setOptions([]);
      setOptionString("");
      setId(null);
      return;
    } else {
      setSurvey((prevSurvey) => ({
        ...prevSurvey,
        questions: [
          ...prevSurvey.questions,
          {
            id: uuid(),
            question: question,
            description: description,
            type: type,
            is_required: is_required,
            disabled: disabled,
            options:
              type === "multi-grid"
                ? { labels: labels, options: multiGridOptions }
                : options,
          },
        ],
      }));

      setQuestion("");
      setDescription("");
      setType("text");
      setIsRequired(false);
      setDisabled(false);
      setOptions([]);
      setOptionString("");
    }
  };

  //  handle edit question
  const editQuestion = (id) => {
    console.log(id);
    const question = survey.questions.find((question) => question.id === id);
    console.log(question)
    setId(id);
    setQuestion(question.question);
    setDescription(question.description);
    setType(question.type);
    setIsRequired(question.is_required);
    setDisabled(question.disabled);
    // return value as new line separated string
    if(question.type === "checkbox" || question.type === "radio" || question.type === "select") {
      const options = question.options.map((option) => option.question);
      setOptionString(options.join("\n"));
      // setOptionString(question.options.join("\n"));
    }
    // setOptions(question.options);
    // setOptionString(
    if (question.type === "multi-grid") {
      setLabelString(question.options.labels.join(","));
      setMultiGridOptionString(question.options.options.join(","));
    }
    // );
    console.log(question)
  };

  // handle delete question
  const deleteQuestion = (id) => {
    setSurvey((prevSurvey) => ({
      ...prevSurvey,
      questions: prevSurvey.questions.filter((question) => question.id !== id),
    }));
  };

  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      instance
        .get(`/survey/${slug}/show`)
        .then((res) => {
          console.log(res.data);
          setSurvey({
            id: res.data.data.survey_id,
            title: res.data.data.title,
            description: res.data.data.description,
            questions: res.data.data.questions,
            isActive: res.data.data.is_active,
            isAnonymous: res.data.data.is_anonymous,
            isPublic: res.data.data.is_public,
            image: res.data.data.image_url,
            startDate: res.data.data.start_date.slice(0, 10),
            endDate: res.data.data.end_date.slice(0, 10),
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [slug]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="sticky-xl-top fw-bold">
              {survey.status ? "Edit Survey" : "Create Survey"}
            </h2>

            {survey.status && (
              <div className="d-flex justify-items-center align-items-center">
                <button
                  className="btn btn-primary mx-2"
                  onClick={handleEditSurvey}
                >
                  Edit
                </button>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-primary mx-2"
                    onClick={handleUpdateSurvey}
                  >
                    Save
                  </button>
                  {isSaving && <Spinner />}
                </div>
              </div>
            )}
          </div>
          {!survey.status ? (
            <form onSubmit={handleCreateSurvey} ref={formRef}>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  name="title"
                  value={survey.title}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  rows="10"
                  onChange={handleChange}
                  name="description"
                  value={survey.description}
                  required
                ></textarea>
              </div>
              {/* <div className="mb-3">
            <label className="form-label">Image</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange}
              name="image"
              value={survey.image}
            />
          </div> */}
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  name="startDate"
                  value={survey.startDate}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  name="endDate"
                  value={survey.endDate}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          ) : (
            <div>
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  background: `url(${survey.image}) no-repeat center center/cover`,
                }}
              ></div>
              <h3>{survey.title}</h3>
              {save && <Spinner />}
              <p>{survey.description}</p>
              <p>
                <strong>Start Date:</strong> {survey.startDate}{" "}
                <strong>End Date:</strong> {survey.endDate}
              </p>

              {/* Add Questions to the survey */}
              <h4>Questions</h4>
              <button
                className="btn btn-primary ms-auto d-block"
                onClick={addQuestion}
              >
                {id ? "Update" : "Add"}
              </button>
              <form ref={editFormRef}>
                {isError && (
                  <div className="my-3">
                    <ShowAlert
                      errorMessage={errorMessage}
                      alertType={"danger"}
                      setIsError={setIsError}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setQuestion(e.target.value)}
                    name="question"
                    value={question}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Type</label>
                  <select
                    className="form-select"
                    onChange={(e) => setType(e.target.value)}
                    name="type"
                    value={type}
                  >
                    <option defaultChecked>Select type</option>
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="range">Range</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="radio">Radio</option>
                    <option value="select">Select</option>
                    <option value="date">Date</option>
                    <option value="time">Time</option>
                    <option value="textarea">Textarea</option>
                    <option value="multi-grid">Multi grid</option>
                    <option value="heading">Heading</option>
                    <option value="paragraph">Paragraph</option>
                    {/* <option value="kaiser-tool">K10</option> */}
                  </select>
                </div>
                {
                  !isNonInputField(type) && (
                    <>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          rows="10"
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                          value={description}
                        ></textarea>
                      </div>
                    </>)
                }
                {requiresOption(type) && (
                  <div className="mb-3">
                    <label className="form-label">Options</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={handleOptions}
                      name="options"
                      value={optionString}
                    ></textarea>
                  </div>
                )}
                {isMultiGrid(type) && (
                  <div>
                    <div className="mb-3">
                      <label className="form-label">Labels</label>
                      <textarea
                        className="form-control"
                        rows="10"
                        onChange={(e) => setLabelString(e.target.value)}
                        onBlur={handleLabels}
                        name="labels"
                        value={labelString}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Options</label>
                      <textarea
                        className="form-control"
                        rows="10"
                        onChange={(e) => setMultiGridOptionString(e.target.value)}
                        onBlur={handleMultiGridOptions}
                        name="labels"
                        value={multiGridOptionString}
                      ></textarea>
                    </div>
                  </div>
                )}
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => setIsRequired(e.target.checked)}
                    name="required"
                    checked={is_required}
                  />
                  <label className="form-check-label">Required</label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => setDisabled(e.target.checked)}
                    name="disabled"
                    checked={disabled}
                  />
                  <label className="form-check-label">Disabled</label>
                </div>
              </form>
              {survey.questions.length > 0 ? (
                survey.questions.map((question) => (
                  <div
                    key={question.id}
                    className="d-flex justify-content-between align-items-top"
                  >
                    <div className="flex-grow-1 mx-2">
                      {renderQuestion(question)}
                    </div>
                    <div style={{ width: "180px" }}>
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => editQuestion(question.id)}
                      >
                        <i className="bi bi-pen-fill"></i>
                      </button>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => deleteQuestion(question.id)}
                      >
                        <i className="bi bi-trash3-fill"></i>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No questions added yet</p>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CreateSurvey;
