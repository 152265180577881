import React from "react";

function NumberInput({
  label,
  onChange,
  description,
  type,
  required,
  disabled,
  name,
  min,
  max,
}) {
  return (
    <div className="mb-3">
      <label className="form-label fw-bold">{label}</label>
      <input
        type={type}
        className="form-control"
        onBlur={onChange}
        required={required}
        disabled={disabled}
        name={name}
        min={min || 0}
        max={max || 2000000000}
      />
      <p className="form-text">{description}</p>
    </div>
  );
}

export default NumberInput;
