import React, { useCallback } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import instance from "../axios";

const isLoggedIn = () => {
  return localStorage.getItem("app-token") ? true : false;
}

function Default() {
  return (
    <>
      {isLoggedIn() ? (
        <>
          {/* <Navbar />
          <div className="container">
            <Outlet />
          </div> */}
          {/* <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow">
              <Outlet />
            </div>
            <footer className="text-center text-lg-start bg-light text-muted py-4">
              <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                <div className="me-5 d-none d-lg-block">
                  <span>Get connected with us on social networks:</span>
                </div>
                <div>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-github"></i>
                  </a>
                </div>
              </section>
            </footer>
          </div> */}
          <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className="flex-grow 
            w-8/12 mx-auto
            ">
              <Outlet />
            </div>
            <footer className="text-center text-lg-start bg-light text-muted py-4">
              <section className="d-flex justify-center justify-lg-between p-4 border-bottom">
                <div className="me-5 d-none d-lg-block">
                  <span>Get connected with us on social networks:</span>
                </div>
                <div>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="" className="me-4 text-reset">
                    <i className="bi bi-github"></i>
                  </a>
                </div>
              </section>
            </footer>
          </div>

        </>
      ) : (
        <Navigate to="/auth/login" />
      )}
    </>
  );
}

export default Default;
