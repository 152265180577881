import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import instance from "../../axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import Checkbox from "../../components/questions/Checkbox";
import DateInput from "../../components/questions/DateInput";
import NumberInput from "../../components/questions/NumberInput";
import Radio from "../../components/questions/Radio";
import RangeInput from "../../components/questions/RangeInput";
import Select from "../../components/questions/Select";
import Text from "../../components/questions/Text";
import TextArea from "../../components/questions/TextArea";
import Spinner from "../../components/Spinner";
import MultiGrid from "../../components/questions/MultiGrid";
import FormHeader from "../../components/questions/FormHeader";
import FormText from "../../components/questions/FormText";
import KaiserTool from "../../components/questions/KaiserTool";

const hasSubmitted = (slug) => {
  const surveyToken = localStorage.getItem(slug);
  if (surveyToken) {
    return true;
  } else {
    return false;
  }
};

function Survey() {
  const { slug } = useParams();
  const [survey, setSurvey] = useState({});
  const [values, setValues] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitted = hasSubmitted(slug);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [multiGridValue, setMultiGridValue] = useState({});
  const [multiGridResponses, setMultiGridResponses] = useState([]);
  const [questions, setQuestions] = useState([]);

  // function to fetch survey from backend
  const getSurvey = useCallback(async () => {
    setIsLoading(true);
    instance
      .get(`/survey/${slug}`)
      .then(({ data }) => {
        setIsLoading(false);
        setSurvey(data.data);
        setQuestions(data.questions);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [slug]);
  // Initialize survey state in a useEffect hook
  useEffect(() => {
    getSurvey();
  }, [getSurvey, slug]);

  // create random light gray color 
  const randomColor = () => {
    // random shade of gray
    const randomShade = Math.floor(Math.random() * 256);
    return `rgb(${randomShade}, ${randomShade}, ${randomShade})`;
  }

  //   function to render question component based on question type
  const renderQuestion = (question) => {
    switch (question.type) {
      case "text":
      case "email":
      case "time":
        return (
          <div className="mb-3">
            <Text
              onChange={handleChange}
              label={question.question}
              description={question.description}
              type={question.type}
              required={question.required}
              disabled={question.disabled}
              name={question.question_id}
            />
          </div>
        );
      case "checkbox":
        return (
          <div className="mb-3">
            <label className="fw-bold">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <div className="row">
              {question.options.map((option) => (
                <div className="" key={option.id}>
                  <Checkbox
                    onChange={handleCheckboxChange}
                    label={option.question}
                    description={option.description}
                    type={question.type}
                    required={question.required}
                    disabled={question.disabled}
                    name={question.question_id}
                    value={option.value}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "radio":
        return (
          <div className="mb-3">
            <label className="fw-bold">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <div className="row">
              {question.options.map((option) => (
                <div className="" key={option.id}>
                  <Radio
                    onChange={handleRadioChange}
                    label={option.question}
                    description={option.description}
                    type={question.type}
                    required={question.required}
                    disabled={question.disabled}
                    name={question.question_id}
                    value={option.value}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "select":
        return (
          <div className="mb-3">
            <label className="fw-bold">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <Select
              onChange={handleChange}
              label={question.question}
              required={question.required}
              disabled={question.disabled}
              name={question.question_id}
              options={question.options}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="mb-3">
            <TextArea
              onChange={handleChange}
              label={question.question}
              description={question.description}
              required={question.required}
              disabled={question.disabled}
              name={question.question_id}
            />
          </div>
        );
      case "date":
        return (
          <DateInput
            onChange={handleChange}
            label={question.question}
            description={question.description}
            required={question.required}
            disabled={question.disabled}
            name={question.question_id}
            type={question.type}
            minDate={question.minDate}
            maxDate={question.maxDate}
          />
        );
      case "number":
        return (
          <NumberInput
            onChange={handleChange}
            label={question.question}
            description={question.description}
            required={question.required}
            disabled={question.disabled}
            name={question.question_id}
            type={question.type}
            min={question.min}
            max={question.max}
          />
        );
      case "range":
        return (
          <RangeInput
            onChange={handleChange}
            label={question.question}
            description={question.description}
            required={question.required}
            disabled={question.disabled}
            name={question.question_id}
            type={question.type}
            min={question.min}
            max={question.max}
          />
        );
      case "multi-grid":
        return (
          <>
            <label className="fw-bold">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <MultiGrid
              headers={question.options.labels}
              options={question.options.options}
              value={multiGridValue}
              setValue={setMultiGridValue}
              onChange={(e) => handleMultiGridOptions(e, question.question_id)}
            />
          </>
        );
      case "heading":
        return (
          <FormHeader title={question.question} />
        );
      case "paragraph":
        return (
          <FormText text={question.question} />
        );
      case "kaiser-tool":
        return (
          <div className="mb-3">
            <label className="form-label">{question.question}</label>
            <p className="form-text">{question.description}</p>
            <KaiserTool
              label={question.question}
              headers={question.options.labels}
              options={question.options.options}
              value={multiGridValue}
              setValue={setMultiGridValue}
            />
          </div>
        )
      default:
        return <></>;
    }
  };

  // function to handle input change
  const handleMultiGridOptions = (e, id) => {
    const data = {
      question_id: id,
      grid: e.target.name,
      response: e.target.value,
      is_anonymous: false,
    };
    const filteredValues = values.filter((value) => value.grid !== data.grid);
    setValues([...filteredValues, data]);
    console.log(values);
  }
  const handleChange = (e) => {
    const data = {
      question_id: e.target.name,
      response: e.target.value,
      is_anonymous: false,
    };

    setValues([...values, data]);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      const data = {
        question_id: e.target.name,
        response: e.target.value,
        is_anonymous: false,
      };

      setValues([...values, data]);
    } else {
      const data = {
        question_id: e.target.name,
        response: e.target.value,
        is_anonymous: false,
      };

      const filteredValues = values.filter(
        (value) => value.question_id !== data.question_id
      );

      setValues(filteredValues);
    }
  };

  // handle Radio change
  const handleRadioChange = (e) => {
    const data = {
      question_id: e.target.name,
      response: e.target.value,
      is_anonymous: false,
    };
    const filteredValues = values.filter(
      (value) => value.question_id !== data.question_id
    );

    setValues([...filteredValues, data]);
  };

  // function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      survey_id: survey.survey_id,
      responses: values,
    };

    instance
      .post("/response", data)
      .then((res) => {
        console.log(res.data);
        setIsSubmitting(false);
        // localStorage.setItem(slug, JSON.stringify(res.data.token));
        navigate(`/survey/submitted/${slug}`);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {submitted ? (
        <Navigate to={`/survey/submitted/${slug}`} />
      ) : (
        <>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="container my-5 bg-light">
              <div>
                {/* <div
                  className="mb-4"
                  style={{
                    width: "100%",
                    height: "300px",
                    background: `url(${survey.image_url}) no-repeat center center/cover`,
                  }}
                ></div> */}

                <h3 className="fw-bold">{survey.title}</h3>
                <p>{survey.description}</p>
                <img src={survey.image_url} alt="" width={'100%'} />
                <hr />
                <form onSubmit={handleSubmit} id="survey-form">
                  {/* {survey.questions?.map((question) => (
                    <div className="card my-3" key={question.question_id}>
                      <div className="card-body">
                        {renderQuestion(question)}
                      </div>
                    </div>
                  ))} */}
                  {/* <pre>
                    {JSON.stringify(questions, null, 2)}
                  </pre> */}
                  {
                    Object.keys(questions).map((key) => {
                      return (
                        <div className="card my-3" key={key} 
                          style={{
                            boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                            // backgroundColor: randomColor()
                          }}
                        >
                          <div className="card-body">
                            {
                              questions[key].map((question) => {
                                return (
                                  <div key={question.question_id}>
                                    {renderQuestion(question)}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                  <div className="d-flex justify-content-center align-items-center">
                    {survey.questions?.length > 0 && (
                      <button
                        type="submit"
                        className="btn btn-primary mx-2"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                    {isSubmitting && <Spinner />}
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Survey;
