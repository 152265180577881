import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function DashboardCard({
  title,
  description,
  startDate,
  endDate,
  slug,
  isActive,
  responseCount,
  changeStatus,
  copyLinkToClipboard,
}) {
  const formatDate = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString("default", { month: "short" });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const [copy, setCopy] = useState(false);

  const truncatedDescription =
    description.length > 100
      ? description.substring(0, 100) + "..."
      : description;

  // show copy message for 2 seconds
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);
  return (
    <>
      {/* <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title fw-bold">{title}</h5>
            <div className="dropdown">
              <button
                className="btn btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-three-dots-vertical"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/admin/surveys/report/${slug}`}
                  >
                    Reports
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/admin/surveys/edit/${slug}`}
                  >
                    Edit
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={changeStatus}>
                    {isActive ? "unpublish" : "publish"}
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item">Delete</Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <span className="card-text">
              <small className="text-muted fw-bold">
                {formatDate(startDate)}
              </small>
            </span>{" "}
            -{" "}
            <span className="card-text">
              <small className="text-muted fw-bold">
                {formatDate(endDate)}
              </small>
            </span>
          </div>
          <p className="card-text">{truncatedDescription}</p>
          <div className="d-flex justify-content-between align-items-center">
            <p className="card-text">
              <small className="text-muted fw-bold">
                {`${responseCount} response${responseCount > 1 ? "s" : ""}`}
              </small>
            </p>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setCopy(true);
                copyLinkToClipboard();
              }}
            >
              {copy ? "Copied" : "Copy Link"}
            </button>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4">
        <div className="flex justify-between items-center">
          <h5 className="text-xl font-bold">{title}</h5>
          <div className="relative">
            <button className="btn btn-sm bg-gray-300 rounded-full p-2" type="button">
              <i className="bi bi-three-dots-vertical"></i>
            </button>
            <ul className="dropdown-menu absolute right-0 mt-2 py-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
              <li>
                <Link className="dropdown-item" to={`/admin/surveys/report/${slug}`}>
                  Reports
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={`/admin/surveys/edit/${slug}`}>
                  Edit
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" onClick={changeStatus}>
                  {isActive ? "Unpublish" : "Publish"}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item">Delete</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-2">
          <span className="text-gray-500 font-bold text-sm">{formatDate(startDate)}</span> -{" "}
          <span className="text-gray-500 font-bold text-sm">{formatDate(endDate)}</span>
        </div>
        <p className="mt-2 text-gray-700">{truncatedDescription}</p>
        <div className="flex justify-between items-center mt-4">
          <p className="text-gray-500 font-bold text-sm">
            {`${responseCount} response${responseCount > 1 ? "s" : ""}`}
          </p>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setCopy(true);
              copyLinkToClipboard();
            }}
          >
            {copy ? "Copied" : "Copy Link"}
          </button>
        </div>
      </div>
    </div> */}
      {/* <div className="bg-white rounded-lg shadow">
        <div className="px-6 py-4">
          <div className="flex justify-between items-center">
            <h5 className="text-xl font-bold">{title}</h5>
            <div className="relative">
              <button className="btn btn-sm bg-gray-300 rounded-full p-2" type="button" onClick={toggleDropdown}>
                <i className="bi bi-three-dots-vertical"></i>
              </button>
              {dropdownOpen && (
                <ul className="dropdown-menu absolute right-0 mt-2 py-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-10" onMouseLeave={closeDropdown}>
                  <li>
                    <Link className="dropdown-item" to={`/admin/surveys/report/${slug}`}>
                      Reports
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={`/admin/surveys/edit/${slug}`}>
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" onClick={changeStatus}>
                      {isActive ? "Unpublish" : "Publish"}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item">Delete</Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="mt-2">
            <span className="text-gray-500 font-bold text-sm">{formatDate(startDate)}</span> -{" "}
            <span className="text-gray-500 font-bold text-sm">{formatDate(endDate)}</span>
          </div>
          <p className="mt-2 text-gray-700">{truncatedDescription}</p>
          <div className="flex justify-between items-center mt-4">
            <p className="text-gray-500 font-bold text-sm">
              {`${responseCount} response${responseCount > 1 ? "s" : ""}`}
            </p>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setCopy(true);
                copyLinkToClipboard();
              }}
            >
              {copy ? "Copied" : "Copy Link"}
            </button>
          </div>
        </div>
      </div> */}
      <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4">
        <div className="flex justify-between items-center">
          <h5 className="text-xl font-bold">{title}</h5>
          <div className="relative">
            <button className="btn btn-sm bg-gray-300 rounded-full p-2" type="button" onClick={toggleDropdown}>
              {/* Remove three dots icon */}
              {dropdownOpen && (
                <ul className="dropdown-menu absolute right-0 mt-2 py-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-10" onMouseLeave={closeDropdown}>
                  <li>
                    <a className="dropdown-item" href={`/admin/surveys/report/${slug}`}>
                      Reports
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href={`/admin/surveys/edit/${slug}`}>
                      Edit
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" onClick={changeStatus}>
                      {isActive ? "Unpublish" : "Publish"}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">Delete</a>
                  </li>
                </ul>
              )}
            </button>
          </div>
        </div>
        <div className="mt-2">
          <span className="text-gray-500 font-bold text-sm">{formatDate(startDate)}</span> -{" "}
          <span className="text-gray-500 font-bold text-sm">{formatDate(endDate)}</span>
        </div>
        <p className="mt-2 text-gray-700">{truncatedDescription}</p>
        <div className="flex justify-between items-center mt-4">
          {/* <div>
            <p className="text-gray-500 font-bold text-sm">
              {`${responseCount} response${responseCount > 1 ? "s" : ""}`}
            </p>
            <div className="flex">
              <button className="no-underline mx-2 px-2 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" onClick={() => {
                setCopy(true);
                copyLinkToClipboard();
              }}>
                {copy ? "Copied" : "Copy"}
              </button>
              <Link className="no-underline mx-2 px-2 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" to={`/admin/surveys/report/${slug}`}>
                Reports
              </Link>
              <Link className="no-underline mx-2 px-2 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" to={`/admin/surveys/edit/${slug}`}>
                Edit
              </Link>
              <button className="no-underline mx-2 px-2 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" onClick={changeStatus}>
                {isActive ? "Unpublish" : "Publish"}
              </button>
              <button className="no-underline mx-2 px-2 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                Delete
              </button>
            </div>
          </div> */}
          <div>
  <p className="text-gray-500 font-bold text-sm">
    {`${responseCount} response${responseCount > 1 ? "s" : ""}`}
  </p>
  <div className="flex flex-wrap mt-4">
    <button className="no-underline mx-2 my-1 px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" onClick={() => {
      setCopy(true);
      copyLinkToClipboard();
    }}>
      {copy ? "Copied" : "Copy"}
    </button>
    <Link className="no-underline mx-2 my-1 px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" to={`/admin/surveys/report/${slug}`}>
      Reports
    </Link>
    <Link className="no-underline mx-2 my-1 px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" to={`/admin/surveys/edit/${slug}`}>
      Edit
    </Link>
    <button className="no-underline mx-2 my-1 px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75" onClick={changeStatus}>
      {isActive ? "Unpublish" : "Publish"}
    </button>
    <button className="no-underline mx-2 my-1 px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
      Delete
    </button>
  </div>
</div>

        </div>
      </div>
    </div>
    </>
  );
}

export default DashboardCard;
