import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "../components/AuthLayout";
import Default from "../components/Default";
import UserLayout from "../components/UserLayout";
import ErrorPage from "../error-page";
import Dashboard from "../views/home/Dashboard";
import Login from "../views/login/Login";
import Register from "../views/register/Register";
import CreateSurvey from "../views/surveys/CreateSurvey";
import HasSubmitted from "../views/surveys/HasSubmitted";
import Survey from "../views/surveys/Survey";
import SurveyReport from "../views/surveys/SurveyReport";
import Surveys from "../views/surveys/Surveys";
import VerifyEmail from "../views/verify-email/VerifyEmail";
import Users from "../views/users/Users";
import User from "../views/users/User";
import Roles from "../views/users/Roles";
import SurveyLayout from "../components/SurveyLayout";
import SurveyDetail from "../views/surveyDetails/SurveyDetail";
import Message from "../messages/Message";
import About from "../views/surveys/About";
import Landing from "../views/landing/Landing";

const router = createBrowserRouter([
  {
    path: "/survey",
    element: <SurveyLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/survey/:slug",
        element: <Survey />,
      },
      {
        path: "/survey/submitted/:slug",
        element: <HasSubmitted />,
      },
    ]
  },
  {
    path: "/",
    element: <UserLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      // {
      //   path: "/surveys/:slug",
      //   element: <Survey />,
      // },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/surveys-page",
        element: <Surveys />
      }
    ],
  },
  {
    path: "/admin",
    element: <Default />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/admin/surveys/create",
        element: <CreateSurvey />,
      },
      {
        path: "/admin/surveys/edit/:slug",
        element: <CreateSurvey />,
      },
      {
        path: "/admin/surveys/report/:slug",
        element: <SurveyReport />,
      },
      {
        path: "/admin/users",
        element: <Users />,
      },
      {
        path: "/admin/users/:id/edit",
        element: <User />,
      },
      {
        path: "/admin/users/:id/roles",
        element: <Roles />,
      },
      {
        path: "/admin/survey-details/create",
        element: <SurveyDetail />
      },
      {
        path: "/admin/messages/inbox",
        element: <Message />
      }
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/auth/register",
        element: <Register />,
      },
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/verify-email/:id",
        element: <VerifyEmail />,
      },
    ],
  },
]);

export default router;
