import React from "react";

function TextArea({ onChange, label, description, required, disabled, name }) {
  return (
    <div className="mb-3">
      <label className="form-label fw-bold">{label}</label>
      <textarea
        className="form-control"
        rows="3"
        onBlur={onChange}
        required={required}
        disabled={disabled}
        name={name}
      ></textarea>
      <p className="form-text">{description}</p>
    </div>
  );
}

export default TextArea;
