import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import instance from "../../axios";
import ShowAlert from "../../components/ShowAlert";
import Spinner from "../../components/Spinner";

function VerifyEmail() {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [verificationCode, setVerificationCode] = useState("");
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResend, setIsResend] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getUser = useCallback(async () => {
    const response = await instance.get(`/users/${id}`);
    setUser(response.data.user);
    setIsLoading(false);
  }, [id]);

  const newToken = () => {
    setIsResend(true);
    instance
      .post("/new-verification-token", {
        email: user.email,
      })
      .then(({ data }) => {
        setIsResend(false);
        setIsResent(true);
      })
      .catch((error) => {
        console.log(error);
        setIsResend(false);
        setIsError(true);
        setErrorMessage(error.response.data.message);
      });
  };

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    instance
      .post("/verify-email", {
        email: user.email,
        token: verificationCode,
      })
      .then(({ data }) => {
        if (data.status) {
          navigate("/auth/login");
        } else {
          setIsError(true);
          setErrorMessage(data.message);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setErrorMessage(error.response.data.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <h1>Verify Email</h1>
          <p>
            A verification email has been sent to <strong>{user.email}</strong>.
            Didn't receive? Click{" "}
            <Link className="text-decoration-none" onClick={newToken}>
              here{" "}
              {isResend ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </Link>{" "}
            to resend.
          </p>
          {isError && (
            <ShowAlert
              errorMessage={errorMessage}
              alertType="danger"
              setIsError={setIsError}
            />
          )}
          {isResent && (
            <ShowAlert
              errorMessage="Verification email has been resent."
              alertType="success"
              setIsError={setIsResent}
            />
          )}
          <form
            onSubmit={handleSubmit}
            className={`d-flex flex-column justify-content-center align-items-center`}
          >
            <div className="mb-3">
              <label htmlFor="verification-code" className="form-label">
                Verification Code
              </label>
              <input
                type="text"
                className="form-control"
                id="verification-code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <button
                type="submit"
                className="btn btn-primary text-center fw-bold mx-2"
              >
                Verify
              </button>
              {isSubmitting && <Spinner />}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default VerifyEmail;
