import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import instance from "../../axios";

function User() {
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getUser = useCallback(async () => {
        setIsLoading(true);
        instance.post(`/users/${id}`).then(({ data }) => {
            setIsLoading(false);
            console.log(data);
            if (data.user) {
                setUser(data.user);
            }
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        }
        );
    }, [id]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    return (
        <>
            {/* {
                isLoading ? <LoadingSpinner /> : (<>
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="card-title">User Details</h3>
                                <Link to={`/admin/users/${id}/roles`} className="btn btn-sm btn-primary">Roles & Permissions</Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {
                                    Object.keys(user).map((key, index) => {
                                        return (
                                            <div className="col-md-6" key={index}>
                                                <div className="form-group">
                                                    <label>{
                                                        key.split("_").map((word) => {
                                                            return word[0].toUpperCase() + word.slice(1);
                                                        }).join(" ")
                                                    }</label>
                                                    <div className="form-control">
                                                        {
                                                            JSON.stringify(user[key])
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </>)
            } */}
            {/* <div>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
      <div className="card">
        <div className="card-header">
          <div className="flex justify-between items-center">
            <h3 className="card-title">User Details</h3>
            <Link to={`/admin/users/${id}/roles`} className="btn btn-sm btn-primary">Roles & Permissions</Link>
          </div>
        </div>
        <div className="card-body">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Object.keys(user).map((key, index) => (
              <div className="mb-4" key={index}>
                <label className="block text-gray-700 font-semibold">{key.split("_").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")}</label>
                <div className="border border-gray-300 rounded p-2">{JSON.stringify(user[key])}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  </div> */}
  {isLoading ? <LoadingSpinner /> : (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">User Details</h3>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {Object.keys(user).map((key, index) => (
          <div key={index} className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {key.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {JSON.stringify(user[key])}
            </dd>
          </div>
        ))}
      </dl>
    </div>
    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <Link to={`/admin/users/${id}/roles`} className="inline-flex no-underline justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        Roles & Permissions
      </Link>
    </div>
  </div>
)}

        </>
    );
}

export default User;