import React from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../logo.png";

function AuthLayout() {
  return (
    <div
      className={`container-fluid d-flex justify-content-center align-items-center bg-info`}
      style={{ minHeight: "100vh", width: "100%" }}
    >
      <div
        id="auth-layout"
        className={`d-flex justify-content-center align-items-center flex-column flex-lg-row shadow-lg p-5 mb-5 bg-body rounded`}
      >
        <div id="auth-layout-left">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div id="auth-layout-right" className="ms-lg-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
