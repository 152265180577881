import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import instance from "../../axios";
import { registerUser } from "../../state/register/register";
import Spinner from "../../components/Spinner";

function Register() {
  const navigate = useNavigate();
  const status = useSelector((state) => state.register.status);
  const message = useSelector((state) => state.register.message);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const handleRequired = () => {
    if (
      user.firstName === "" ||
      user.lastName === "" ||
      user.email === "" ||
      user.phone === "" ||
      user.username === "" ||
      user.password === "" ||
      user.confirmPassword === ""
    ) {
      setIsError(true);
      setErrorMessage("All fields are required");
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleRequired()) {
      return;
    }
    if (user.password !== user.confirmPassword) {
      setIsError(true);
      setErrorMessage("Passwords do not match");
      return;
    }
    setIsLoading(true);
    instance
      .post("/register", {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone: user.phone,
        username: user.username,
        password: user.password,
        password_confirmation: user.confirmPassword,
      })
      .then(({ data }) => {
        if (data.status) {
          navigate("/auth/verify-email/" + data.user.id);
        }
      })
      .catch((err) => {
        setIsError(true);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div
      className={`d-flex justify-content-center align-items-center flex-column`}
      id="register"
    >
      <h1 className={`fw-bold`} style={{ fontSize: "32px" }}>
        Register
      </h1>
      <p>
        or{" "}
        <Link to="/auth/login" className="text-decoration-none">
          sign in
        </Link>
      </p>
      {isError && (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Error!</strong> {errorMessage}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className={`d-flex flex-column justify-content-center align-items-center`}
      >
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            value={user.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            value={user.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={user.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            type="text"
            className="form-control"
            id="phone"
            value={user.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={user.username}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={user.password}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={user.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button type="submit" className="btn btn-primary text-center fw-bold mx-2">
            Register
          </button>
          {isloading && <Spinner />}
        </div>
        <label className="form-check-label" htmlFor="flexCheckDefault">
          <Link to="/" className="text-decoration-none d-block mt-2">
            Terms and Conditions
          </Link>
        </label>
      </form>
    </div>
  );
}

export default Register;
