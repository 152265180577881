import React from "react";

function Radio({
  onChange,
  label,
  description,
  type,
  required,
  disabled,
  name,
  value,
}) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type={type}
        name={name}
        onChange={onChange}
        required={required}
        disabled={disabled}
        value={value}
      />
      <label className="form-check-label">{label}</label>
      <p className="form-text">{description}</p>
    </div>
  );
}

export default Radio;
