import React, { useCallback, useEffect, useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import instance from "../../axios";
import { Link } from "react-router-dom";

function Users() {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    // function to fetch users from api

    const getUsers = useCallback(async () => {
        setIsLoading(true);
        instance.post("/users").then(({ data }) => {
            setIsLoading(false);
            if (data.users.length > 0) {
                setUsers(data.users);

            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        }
        );
    }, []);

    const deleteUser = useCallback(async (id) => {
        setIsLoading(true);
        instance.post(`/delete-users/${id}`).then(({ data }) => {
            setIsLoading(false);
            if (data.success) {
                getUsers();
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        }
        );
    }, [getUsers]);


    useEffect(() => {
        getUsers();
    }, [getUsers]);
    return (
        <>
            {/* {
                isLoading ? <LoadingSpinner /> : (<>
                    {
                        users.length > 0 ? (<>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Roles</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{user.id}</th>
                                                    <td>{user.name}</td>
                                                    <td>{user.username}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.phone}</td>
                                                    <td>{user.roles.map((role, index) => {
                                                        return (
                                                            <span key={index}>{role.name}</span>
                                                        )
                                                    })}</td>
                                                    <td>
                                                        {
                                                            user.status
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link className="btn btn-primary btn-sm mx-2"
                                                            to={`/admin/users/${user.user_id}/edit`}
                                                        >
                                                            <i className="bi bi-pencil-square"></i>
                                                        </Link>
                                                        <button className="btn btn-danger btn-sm mx-2">
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </>) : (<h1>No users found</h1>)
                    }
                </>)
            } */}
            <div>
                <h1 className="text-3xl font-bold mb-4">User List</h1>
                <p className="text-gray-500 mb-8">Browse and manage users in the system.</p>

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {users.length > 0 ? (
                            //   <table className="min-w-full divide-y divide-gray-200">
                            //     <thead>
                            //       <tr>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           ID
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Name
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Username
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Email
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Phone
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Roles
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Status
                            //         </th>
                            //         <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            //           Actions
                            //         </th>
                            //       </tr>
                            //     </thead>
                            //     <tbody className="bg-white divide-y divide-gray-200">
                            //       {users.map((user, index) => (
                            //         <tr key={index}>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.id}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">
                            //             {user.roles.map((role, index) => (
                            //               <span key={index}>{role.name}</span>
                            //             ))}
                            //           </td>
                            //           <td className="px-6 py-4 whitespace-nowrap">{user.status}</td>
                            //           <td className="px-6 py-4 whitespace-nowrap">
                            //             <Link
                            //               to={`/admin/users/${user.user_id}/edit`}
                            //               className="btn btn-primary btn-sm mx-2"
                            //             >
                            //               <i className="bi bi-pencil-square"></i>
                            //             </Link>
                            //             <button className="btn btn-danger btn-sm mx-2">
                            //               <i className="bi bi-trash"></i>
                            //             </button>
                            //           </td>
                            //         </tr>
                            //       ))}
                            //     </tbody>
                            //   </table>
                            // <div className="overflow-x-auto">
                            //     <table className="table-auto border-collapse border border-gray-300">
                            //       <thead>
                            //         <tr>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">ID</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Name</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Username</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Email</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Phone</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Roles</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Status</th>
                            //           <th className="px-4 py-2 bg-gray-100 border-b">Actions</th>
                            //         </tr>
                            //       </thead>
                            //       <tbody>
                            //         {users.map((user, index) => (
                            //           <tr key={index}>
                            //             <td className="px-4 py-2 border-b">{user.id}</td>
                            //             <td className="px-4 py-2 border-b">{user.name}</td>
                            //             <td className="px-4 py-2 border-b">{user.username}</td>
                            //             <td className="px-4 py-2 border-b">{user.email}</td>
                            //             <td className="px-4 py-2 border-b">{user.phone}</td>
                            //             <td className="px-4 py-2 border-b">
                            //               {user.roles.map((role, index) => (
                            //                 <span key={index} className="mr-2 inline-block rounded bg-gray-200 px-2 py-1 text-sm font-semibold text-gray-700">
                            //                   {role.name}
                            //                 </span>
                            //               ))}
                            //             </td>
                            //             <td className="px-4 py-2 border-b">{user.status}</td>
                            //             <td className="px-4 py-2 border-b">
                            //               <Link
                            //                 to={`/admin/users/${user.user_id}/edit`}
                            //                 className="btn btn-primary btn-sm mr-2"
                            //               >
                            //                 <i className="bi bi-pencil-square"></i>
                            //               </Link>
                            //               <button className="btn btn-danger btn-sm">
                            //                 <i className="bi bi-trash"></i>
                            //               </button>
                            //             </td>
                            //           </tr>
                            //         ))}
                            //       </tbody>
                            //     </table>
                            //   </div>
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            ID
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Username
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Email
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Roles
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Status
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {users.map((user, index) => (
                                                        <tr key={index}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.id}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.name}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.username}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.email}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.phone}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">
                                                                    {user.roles.map((role, index) => (
                                                                        <span key={index} className="mr-2 inline-block rounded bg-gray-200 px-2 py-1 text-sm font-semibold text-gray-700">
                                                                            {role.name}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{user.status}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <Link
                                                                    to={`/admin/users/${user.user_id}/edit`}
                                                                    className="btn btn-primary btn-sm mr-2"
                                                                >
                                                                    <i className="bi bi-pencil-square"></i>
                                                                </Link>
                                                                <button className="btn btn-danger btn-sm"
                                                                    onClick={() => deleteUser(user.user_id)}
                                                                >
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <h1>No users found</h1>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default Users;