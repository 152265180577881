import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counter";
import registerReducer from "./register/register";

export default configureStore({
  reducer: {
    counter: counterReducer,
    register: registerReducer,
  },
});
