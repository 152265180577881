import React from "react";

function Text({
  onChange,
  label,
  description,
  type,
  required,
  disabled,
  name,
}) {
  return (
    <>
      <div className="mb-3">
        <label className="form-label fw-bold">{label}</label>
        <input
          type={type}
          className="form-control"
          onBlur={onChange}
          required={required}
          disabled={disabled}
          name={name}
        />
        <p className="form-text">{description}</p>
      </div>
    </>
  );
}

export default Text;
