import React from "react";

function DateInput({
  onChange,
  label,
  description,
  type,
  required,
  disabled,
  name,
  minDate,
  maxDate,
}) {
  return (
    <div className="mb-3">
      <label className="form-label fw-bold">{label}</label>
      <input
        type={type}
        className="form-control"
        onChange={onChange}
        required={required}
        disabled={disabled}
        name={name}
        min={minDate || "1900-01-01"}
        max={maxDate || "2100-01-01"}
      />
      <div className="form-text">{description}</div>
    </div>
  );
}

export default DateInput;
