import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import UserNavbar from "./UserNavbar";
const isLoggedIn = () => {
  return localStorage.getItem("app-token") ? true : false;
};
function UserLayout() {
  return (
    <div className="flex flex-col min-h-screen">
      {isLoggedIn() ? <Navbar /> : <UserNavbar />}
      <div className="flex-grow">
        <Outlet />
      </div>
      <footer className="text-center text-lg-start bg-light text-muted py-4">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <a href="" className="me-4 text-reset">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="bi bi-linkedin"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="bi bi-github"></i>
            </a>
          </div>
        </section>
      </footer>
    </div>
  );
}

export default UserLayout;
