import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import instance from "../axios";

function Navbar() {
  const navigate = useNavigate();

  const checkRole = (role) => {
    const roles = JSON.parse(localStorage.getItem("app-user-role"))
    if (roles) {
      const found = roles.find((element) => element.name === role);
      if (found) {
        return true;
      }
    }
  };

  const logout = () => {
    instance
      .post("/logout")
      .then(({ data }) => {
        if (data.status) {
          localStorage.removeItem("app-token");
          localStorage.removeItem("app-user");
          localStorage.removeItem("app-user-role");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <header className="p-3 mb-3 border-bottom">
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          {/* <Link
            to="/"
            className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none"
          >
            <img src={logo} alt="Logo" style={{ width: "100px" }} />
          </Link> */}
          <Link
            to="/"
            className="flex items-center mb-2 mb-lg-0 text-dark text-decoration-none"
          >
            <img
              src={logo}
              alt="Logo"
              className="w-16 h-16 object-contain rounded-full shadow-md"
            />
            <span className="ml-2 text-lg font-semibold">
              SU - Mental Health Chat
            </span>
          </Link>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></ul>

          {/* <form
            className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3"
            role="search"
          >
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              aria-label="Search"
            />
          </form> */}

          {/* <div class="dropdown text-end">
            <Link
              to="/"
              class="d-block no-underline link-dark text-decoration-none dropdown-toggle flex items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://github.com/mdo.png"
                alt="mdo"
                width="32"
                height="32"
                class="rounded-circle mr-2"
              />
              <span class="text-sm">
                Welcome, {JSON.parse(localStorage.getItem("app-user")).first_name} {JSON.parse(localStorage.getItem("app-user")).last_name}
              </span>
            </Link>
            <ul class="dropdown-menu text-small">
              {checkRole("admin") || checkRole("super-admin") ? (
                <>
                  <li>
                    <Link class="dropdown-item" to="/admin/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/admin/surveys/create">
                      New
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/admin/users">
                      Users
                    </Link>
                  </li>
                </>
              ) : null}
              <li>
                <Link class="dropdown-item" to="/">
                  Profile
                </Link>
              </li>
              <li>
                <Link class="dropdown-item" to="/admin/messages/inbox">
                  Inbox
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link class="dropdown-item" onClick={logout}>
                  Sign out
                </Link>
              </li>
            </ul>
          </div> */}

          {/* <div class="relative">
  <div class="flex items-center">
    <img
      src="https://github.com/mdo.png"
      alt="mdo"
      width="32"
      height="32"
      class="rounded-circle mr-2"
    />
    <Link
      to="/"
      class="text-sm font-medium text-gray-900 hover:text-gray-700"
    >
      Welcome, {JSON.parse(localStorage.getItem("app-user")).first_name} {JSON.parse(localStorage.getItem("app-user")).last_name}
    </Link>
    <button
      class="ml-2 flex-shrink-0 p-1 text-gray-400 rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
      aria-label="Toggle menu"
    >
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 2a1 1 0 011 1v14a1 1 0 01-2 0V3a1 1 0 011-1zm-7.071 7.071a3 3 0 104.243 4.243l-4.95 4.95a1 1 0 101.414 1.414l4.95-4.95a3 3 0 10-4.243-4.243l4.95-4.95a1 1 0 00-1.414-1.414l-4.95 4.95z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
  <div class="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg origin-top-right focus:outline-none">
    <div class="py-1">
      {checkRole("admin") || checkRole("super-admin") ? (
        <>
          <Link
            to="/admin/dashboard"
            class="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Dashboard
          </Link>
          <Link
            to="/admin/surveys/create"
            class="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            New
          </Link>
          <Link
            to="/admin/users"
            class="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Users
          </Link>
          <hr class="my-1" />
        </>
      ) : null}
      <Link
        to="/"
        class="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Profile
      </Link>
      <Link
        to="/admin/messages/inbox"
        class="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Inbox
      </Link>
    </div>
    <hr class="my-1" />
    <div class="py-1">
      <Link
        onClick={logout}
        class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Sign out
      </Link>
    </div>
  </div>
</div> */}

          {/* <div class="relative">
  <button
    type="button"
    class="flex items-center text-sm font-medium text-gray-900 hover:text-gray-700 focus:outline-none"
    aria-label="Toggle menu"
    aria-haspopup="true"
  >
    <img
      src="https://github.com/mdo.png"
      alt="mdo"
      width="32"
      height="32"
      class="rounded-circle mr-2"
    />
    <span>
      Welcome, {JSON.parse(localStorage.getItem("app-user")).first_name} {JSON.parse(localStorage.getItem("app-user")).last_name}
    </span>
    <svg
      class="w-4 h-4 ml-1 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </button>
  <div class="absolute right-0 hidden mt-2 w-48 bg-white rounded-md shadow-lg origin-top-right focus:outline-none">
    <div class="py-1">
      {checkRole("admin") || checkRole("super-admin") ? (
        <>
          <Link
            to="/admin/dashboard"
            class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Dashboard
          </Link>
          <Link
            to="/admin/surveys/create"
            class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            New
          </Link>
          <Link
            to="/admin/users"
            class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Users
          </Link>
          <hr class="my-1" />
        </>
      ) : null}
      <Link
        to="/"
        class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Profile
      </Link>
      <Link
        to="/admin/messages/inbox"
        class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Inbox
      </Link>
    </div>
    <hr class="my-1" />
    <div class="py-1">
      <Link
        onClick={logout}
        class="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
      >
        Sign out
      </Link>
    </div>
  </div>
</div> */}
          <div className="relative">
            <button
              type="button"
              className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-700 focus:outline-none"
              aria-label="Toggle menu"
              aria-haspopup="true"
              onClick={toggleDropdown}
            >
              <img
                src="https://github.com/mdo.png"
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle mr-2"
              />
              <span>
                Welcome, {JSON.parse(localStorage.getItem('app-user')).first_name} {JSON.parse(localStorage.getItem('app-user')).last_name}
              </span>
              <svg
                className={`w-4 h-4 ml-1 text-gray-400 ${isOpen ? 'transform rotate-180' : ''}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg origin-top-right focus:outline-none">
                <div className="py-1">
                  {checkRole("admin") || checkRole("super-admin") ? (
                    <>
                      <Link
                        to="/admin/dashboard"
                        className="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Dashboard
                      </Link>
                      <Link
                        to="/admin/surveys/create"
                        className="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        New
                      </Link>
                      <Link
                        to="/admin/users"
                        className="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Users
                      </Link>
                      <hr className="my-1" />
                    </>
                  ) : null}
                  <Link
                    to="/"
                    className="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Profile
                  </Link>
                  <Link
                    to="/admin/messages/inbox"
                    className="block px-4 no-underline py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Inbox
                  </Link>
                </div>
                <hr className="my-1" />
                <div className="py-1">
                  <Link
                    onClick={logout}
                    className="block no-underline px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            )}
          </div>




          {/* <div className="dropdown text-end">
            <Link
              to="/"
              className="d-block no-underline link-dark text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
               {`Welcome, ${JSON.parse(localStorage.getItem("app-user")).first_name} ${JSON.parse(localStorage.getItem("app-user")).last_name} `}
              <img
                src="https://github.com/mdo.png"
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle"
              />
            </Link>
            <ul className="dropdown-menu text-small">
              {checkRole("admin") || checkRole("super-admin") ? (
                <>
                  <li>
                    <Link className="dropdown-item" to="/admin/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/admin/surveys/create">
                      New
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/admin/users">
                      Users
                    </Link>
                  </li>
                </>
              ): null}
              <li>
                <Link className="dropdown-item" to="/">
                  Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/admin/messages/inbox">
                  Inbox
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" onClick={logout}>
                  Sign out
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
