import React from "react";

function Select({
  onChange,
  label,
  required,
  disabled,
  name,
  options,
}) {
  return (
    <select
      className="form-select"
      onChange={onChange}
      name={name}
      required={required}
      disabled={disabled}
    >
      <option defaultValue="">{label}</option>
      {options.map((option) => (
        <option key={option.id} value={option.value}>
          {option.question}
        </option>
      ))}
    </select>
  );
}

export default Select;
