import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../logo.png"

function UserNavbar() {
  return (
    <header className="p-3 mb-3 border-b">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <Link to="/" className="flex-shrink-0 text-dark text-decoration-none">
          <img src={logo} alt="Logo" className="w-24 md:w-32" />
        </Link>

        <div className="flex-shrink-0 order-2 md:order-3 space-x-2">
          <Link to={"/about"} className='font-bold no-underline text-gray-800 hover:text-gray-600'>
            About
          </Link>
          {/* <Link
            to={"/auth/login"}
            className='px-3 py-2 bg-blue-600 text-white font-bold no-underline rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          >
            Sign In
          </Link>
          <Link
            to={"/auth/register"}
            className='px-3 py-2 bg-blue-600 text-white font-bold no-underline rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          >
            Register
          </Link> */}
        </div>
      </div>
    </header>

    //     <header className="p-3 mb-3 border-b">
    //   <div className="container mx-auto flex flex-wrap items-center justify-between">
    //     <Link to="/" className="flex-shrink-0 text-dark text-decoration-none">
    //       <img src={logo} alt="Logo" className="w-24 md:w-32"/>
    //     </Link>

    //     <div className="flex-shrink-0 order-2 md:order-3">
    //       <Link to={"/about"} className='mx-2 font-bold text-gray-800 hover:text-gray-600'>About</Link>
    //       <Link to={"/auth/login"} className='px-3 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>Sign In</Link>
    //       <Link to={"/auth/register"} className='px-3 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>Register</Link>
    //     </div>

    //     {/* <div className="flex-shrink-0 order-3 mt-3 md:mt-0 md:order-2">
    //       <div className="dropdown text-end">
    //         <Link to="/" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    //           <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle"/>
    //         </Link>
    //         <ul className="dropdown-menu text-small">
    //           <li><Link className="dropdown-item" to="/surveys">Surveys</Link></li>
    //           <li><Link className="dropdown-item" to="/surveys/create">New</Link></li>
    //           <li><Link className="dropdown-item" to="/">Settings</Link></li>
    //           <li><Link className="dropdown-item" to="/">Profile</Link></li>
    //           <li><hr className="dropdown-divider"/></li>
    //           <li><Link className="dropdown-item" to="/">Sign out</Link></li>
    //         </ul>
    //       </div>
    //     </div> */}
    //   </div>
    // </header>

    // <header className="p-3 mb-3 border-b">
    //   <div className="container mx-auto">
    //     <div className="flex flex-wrap items-center justify-center lg:justify-start">
    //       <Link to="/" className="flex items-center mb-2 mb-lg-0 text-dark text-decoration-none">
    //         <img src={logo} alt="Logo" style={{ width: "100px" }} />
    //       </Link>

    //       <ul className="flex-col flex-grow hidden space-y-2 lg:flex lg:flex-row lg:space-y-0 lg:space-x-4">
    //         {/* Add your navigation links here */}
    //         <li><Link to="/about" className="font-semibold text-gray-800 hover:text-blue-600">About</Link></li>
    //       </ul>

    //       <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:justify-end">
    //         <Link to="/auth/login" className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-lg lg:mr-2">Sign In</Link>
    //         <Link to="/auth/register" className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-lg">Register</Link>
    //       </div>
    //     </div>
    //   </div>
    // </header>

    // <header className="p-3 mb-3 border-bottom">
    //   <div className="container-fluid">
    //     <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
    //       <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
    //         <img src={logo} alt="Logo"
    //           style={{ width: "100px" }}
    //         />
    //       </Link>

    //       <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">

    //       </ul>

    //       {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
    //       <input type="search" className="form-control" placeholder="Search..." aria-label="Search"/>
    //     </form> */}

    //       <Link to={"/about"} className='fw-bold mx-2 text-decoration-none'>About</Link>
    //       <Link to={"/auth/login"} className='btn btn-sm btn-primary fw-bold text-white mx-2'>Sign In</Link>
    //       <Link to={"/auth/register"} className='btn btn-sm btn-primary fw-bold text-white'>Register</Link>

    //       {/* <div className="dropdown text-end">
    //       <Link to="/" className="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    //         <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle"/>
    //       </Link>
    //       <ul className="dropdown-menu text-small">
    //         <li><Link className="dropdown-item" to="/surveys">Surveys</Link></li>
    //         <li><Link className="dropdown-item" to="/surveys/create">New</Link></li>
    //         <li><Link className="dropdown-item" to="/">Settings</Link></li>
    //         <li><Link className="dropdown-item" to="/">Profile</Link></li>
    //         <li><hr className="dropdown-divider"/></li>
    //         <li><Link className="dropdown-item" to="/">Sign out</Link></li>
    //       </ul>
    //     </div> */}
    //     </div>
    //   </div>
    // </header>
  )
}

export default UserNavbar