import React from 'react'
import { Link } from 'react-router-dom'

function HasSubmitted() {
  return (
    <div>
        <h1>Thank you for your submission!</h1>
        <p>
            You have successfully submitted your survey. You can now close this window or click the button below to return to the home page.
        </p>
        {/* <Link to="/" className="btn btn-primary">Return to Home</Link> */}
        <p>
          You can now close this window.
        </p>
    </div>
  )
}

export default HasSubmitted