import React, { useState } from 'react'

function SurveyDetail() {
    const [title, settitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('public');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    return (
        <>
            <div className='container'>
                <h1> Create Survey </h1>
                <p>
                    Create a new survey. Fill all the details and click on create button.
                </p>
                <hr />
                <form>
                    <div className='form-group'>
                        <label htmlFor='title'>Title</label>
                        <input type='text' className='form-control' id='title' placeholder='Enter title' />
                    </div>
                </form>
            </div>
        </>
    )
}

export default SurveyDetail