import React from "react";

function RangeInput({
  onChange,
  label,
  description,
  type,
  required,
  disabled,
  name,
  min,
  max,
}) {
  return (
    <div className="mb-3">
      <label className="form-label fw-bold">{label}</label>
      <input
        type={type}
        className="form-range"
        min={min || 0}
        max={max || 5}
        onChange={onChange}
        required={required}
        disabled={disabled}
        name={name}
      />
      <p className="form-text">{description}</p>
    </div>
  );
}

export default RangeInput;
