import React from 'react'
import user1 from "../../user-1.png"
import user2 from "../../user-2.png"
import user3 from "../../question-1.png"
import { Link } from 'react-router-dom'

function Landing() {
  return (
    <div className='container mx-auto'>
        <h1 className='text-4xl text-center font-bold mt-10'>Welcome to the Soroti University Mental Health App</h1>
        <div className='flex justify-center mt-10 w-full gap-5'>
            <div className='w-1/3 border border-gray-400 rounded p-5 shadow-lg'>
                <Link to='/survey/mental-health-is-wealth' className='text-center text-decoration-none'>
                    <img src={user1} alt='user-1' className='w-20 mx-auto' />
                    <h3 className='text-2xl font-bold'>Respondent</h3>
                </Link>
            </div>
            <div className='w-1/3 border border-gray-400 rounded p-5 shadow-lg'>
                <Link to='/auth/login' className='text-center text-decoration-none'>
                    <img src={user2} alt='user-1' className='w-20 mx-auto' />
                    <h3 className='text-2xl font-bold'>Admin</h3>
                </Link>
            </div>
            <div className='w-1/3 border border-gray-400 rounded p-5 shadow-lg'>
                <Link to='/auth/login' className='text-center text-decoration-none'>
                    <img src={user3} alt='user-1' className='w-20 mx-auto' />
                    <h3 className='text-2xl font-bold'>Student</h3>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Landing