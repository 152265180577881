import React from 'react'
import logo from "../../logo.png"

function About() {
  return (
    <div className="container mx-auto">
      {/* logo */}
      <div className="flex justify-center">
        <img src={logo} alt="logo" className="w-1/2" />
      </div>
      <h1 className="text-4xl text-center font-bold mt-10">About</h1>
      <p className="mt-5 text-lg leading-relaxed">
        Welcome to our data collection tool designed to help medical students at Soroti University. Our app is a powerful tool that provides mental health counselors with an effective way to assess the mental health needs of students and offer personalized support to those who need it. The app is specifically designed to address the unique challenges faced by medical students, who are often under immense stress due to the rigorous demands of their studies.
      </p>
      <h3 className="text-2xl text-center font-bold mt-10">Purpose</h3>
      <p className="mt-5 text-lg leading-relaxed">
        The purpose of our data collection tool is to provide a comprehensive mental health assessment to medical students at Soroti University. Through the app, counselors can create different forms that students can fill out to help assess their mental health status. This data is then used to identify students who may be at risk of mental health challenges and connect them with the appropriate support services.
      </p>
      <p className="mt-5 text-lg leading-relaxed">
        Our app offers students a confidential way to share their experiences and connect with counselors who can provide personalized support. Through the app, students can receive guidance on a range of issues, including anxiety, depression, stress management, and more. By offering this support, we aim to help medical students manage their mental health challenges, improve their academic performance, and ultimately succeed in their studies.
      </p>
      <p className="mt-5 text-lg leading-relaxed">
        In summary, our data collection tool is an essential resource for medical students at Soroti University. By leveraging technology, we aim to provide students with personalized support that meets their unique mental health needs. We hope that our app will be an invaluable tool for both students and counselors and will help create a healthier and more supportive academic community.
      </p>
    </div>

    // tailwind container
    // <div className="container mx-auto">
    //     {/* logo */}
    //     <div className="flex justify-center">
    //         <img src={logo} alt="logo" className="w-1/2" />
    //     </div>
    //     <h1 className="text-3xl text-center font-bold mt-10">About</h1>
    //     <p className="mt-5">
    //         Welcome to our data collection tool designed to help medical students at Soroti University. Our app is a powerful tool that provides mental health counselors with an effective way to assess the mental health needs of students and offer personalized support to those who need it. The app is specifically designed to address the unique challenges faced by medical students, who are often under immense stress due to the rigorous demands of their studies.
    //     </p>
    //     <h3 className="text-2xl text-center font-bold mt-10">Purpose</h3>
    //     <p className="mt-5">
    //         The purpose of our data collection tool is to provide a comprehensive mental health assessment to medical students at Soroti University. Through the app, counselors can create different forms that students can fill out to help assess their mental health status. This data is then used to identify students who may be at risk of mental health challenges and connect them with the appropriate support services.
    //     </p>
    //     <p className="mt-5">
    //         Our app offers students a confidential way to share their experiences and connect with counselors who can provide personalized support. Through the app, students can receive guidance on a range of issues, including anxiety, depression, stress management, and more. By offering this support, we aim to help medical students manage their mental health challenges, improve their academic performance, and ultimately succeed in their studies.
    //     </p>
    //     <p className="mt-5">
    //         In summary, our data collection tool is an essential resource for medical students at Soroti University. By leveraging technology, we aim to provide students with personalized support that meets their unique mental health needs. We hope that our app will be an invaluable tool for both students and counselors and will help create a healthier and more supportive academic community.
    //     </p>
    // </div>
  )
}

export default About