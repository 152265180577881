import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../axios";
import ShowAlert from "../../components/ShowAlert";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .post("/login", user)
      .then(({ data }) => {
        if (data.status) {
          if (data.is_verified !== null) {
            localStorage.setItem("app-token", data.access_token);
            localStorage.setItem("app-user", JSON.stringify(data.user));
            localStorage.setItem("app-user-role", JSON.stringify(data.role));
            console.log(data);
            navigate("/surveys-page");
          } else {
            navigate("/auth/verify-email/" + data.user.id);
          }
        } else {
          setIsLoading(false);
          setIsError(true);
          setErrorMessage(data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage(err.response.data.message);
      });
  };

  // redirect to dashboard if user is logged in
  useEffect(() => {
    if (localStorage.getItem("app-token")) {
      navigate("/surveys-page");
    }
  }, [navigate]);
  return (
    <div
      className={`d-flex justify-content-center align-items-center flex-column`}
    >
      <h1 className={`fw-bold`} style={{ fontSize: "32px" }}>
        Sign In
      </h1>
      <p>
        or{" "}
        <Link to="/auth/register" className="text-decoration-none">
          register
        </Link>
      </p>
      {isError && (
        <ShowAlert
          errorMessage={errorMessage}
          alertType="danger"
          setIsError={setIsError}
        />
      )}
      <form
        onSubmit={handleSubmit}
        className={`d-flex flex-column justify-content-center align-items-center`}
      >
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={user.username}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={user.password}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex-justify-content-center">
          <button
            type="submit"
            className="btn btn-primary text-center fw-bold mx-2"
          >
            Sign In
          </button>
          {isLoading ? <Spinner /> : null}
        </div>
        <Link to="/" className="text-decoration-none d-block mt-2">
          Terms and Conditions
        </Link>
      </form>
    </div>
  );
}

export default Login;
