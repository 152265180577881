import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import instance from "../../axios";
import LoadingSpinner from "../../components/LoadingSpinner";
function Surveys() {
  const [surveys, setSurveys] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // function to fetch surveys from api
  const getSurveys = useCallback(async () => {
    setIsLoading(true);
    instance
      .get("/survey")
      .then(({ data }) => {
        if (data.data.length > 0) {
          setSurveys(data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  // Initialize surveys state in a useEffect hook
  useEffect(() => {
    getSurveys();
  }, [getSurveys]);

  // function to keep survey description to 100 characters
  const truncate = (str) => {
    return str?.length > 250 ? str.substr(0, 250 - 1) + "..." : str;
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
  {/* Tailwind CSS */}
  <div className="container mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold text-gray-800">
        Available Surveys
      </h1>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {surveys ? (
        surveys.map((survey) => (
          <div
            key={survey.id}
            className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-lg"
          >
            {/* image */}
            <img
              className="object-cover w-full h-64 rounded-lg"
              src={survey.image_url}
              alt={survey.title}
            />
            <h2 className="text-2xl font-bold text-gray-800">
              {survey.title}
            </h2>
            <p className="text-gray-600">{truncate(survey.description)}</p>
            <Link
              to={`/survey/${survey.slug}`}
              className="no-underline px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              Take Survey
            </Link>
          </div>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800">
            No Surveys Available
          </h2>
          <p className="text-gray-600">Please check back later</p>
        </div>
      )}
    </div>
  </div>
</div>

        // <div>
        //   {/* Tailwind CSS */}
        //   <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        //     <div className="flex flex-col items-center justify-center">
        //       <h1 className="text-4xl font-bold text-gray-800">
        //         Available Surveys
        //       </h1>
        //     </div>
        //     <div className="flex flex-wrap justify-center -m-4">
        //       {surveys ? (
        //         surveys.map((survey) => (
        //           <div
        //             key={survey.id}
        //             className="flex flex-col items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4"
        //           >
        //             {/* image */}
        //             <img
        //               className="object-cover w-full h-64 rounded-lg"
        //               src={survey.image_url}
        //               alt={survey.title}
        //             />
        //             <h2 className="text-2xl font-bold text-gray-800">
        //               {survey.title}
        //             </h2>
        //             <p className="text-gray-600">{truncate(survey.description)}</p>
        //             <Link
        //               to={`/survey/${survey.slug}`}
        //               className="no-underline px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        //             >
        //               Take Survey
        //             </Link>
        //           </div>
        //         ))
        //       ) : (
        //         <div className="flex flex-col items-center justify-center w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
        //           <h2 className="text-2xl font-bold text-gray-800">
        //             No Surveys Available
        //           </h2>
        //           <p className="text-gray-600">Please check back later</p>
        //         </div>
        //       )}
        //     </div>
        //   </div>
        // </div>
        // <div>
        //   {/* Tailwind CSS */}
        //   <div className="container mx-auto max-w-screen-xl">
        //     <div className="flex flex-col items-center justify-center">
        //       <h1 className="text-4xl font-bold text-gray-800">
        //         Available Surveys
        //       </h1>
        //     </div>
        //     <div className="flex items-center justify-center">
        //       {
        //         surveys ? surveys.map((survey) => (
        //           <div
        //             key={survey.id}
        //             className="flex flex-col items-center justify-center w-1/2 p-4 m-4 bg-white rounded-lg shadow-lg"
        //           >
        //             {/* image */}
        //             <img
        //               className="object-cover w-full h-full rounded-lg"
        //               src={survey.image_url}
        //               alt={survey.title}
        //             />
        //             <h2 className="text-2xl font-bold text-gray-800">
        //               {survey.title}
        //             </h2>
        //             <p className="text-gray-600">{truncate(survey.description)}</p>
        //             <Link
        //               to={`/survey/${survey.slug}`}
        //               className="no-underline px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        //             >
        //               Take Survey
        //             </Link>
        //           </div>
        //         )) : (<div className="flex flex-col items-center justify-center w-1/2 p-4 m-4 bg-white rounded-lg shadow-lg">
        //           <h2 className="text-2xl font-bold text-gray-800">
        //             No Surveys Available
        //           </h2>
        //           <p className="text-gray-600">Please check back later</p>
        //         </div>
        //         )
        //       }
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
}

export default Surveys;
