import { createSlice } from "@reduxjs/toolkit";
import instance from "../../axios"

export const registerSlice = createSlice({
  name: "register",
  initialState: {
    value: {},
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {
    registerUser: (state, action) => {
        state.value = action.payload;
        instance.post("/register", state.value)
        .then((response) => {
            state.value = response.data.user;
            state.status = "success";
            return response
        })
        .catch((error) => {
            state.error = error;
            state.status = "failed";
            state.message = error.response.data.message;
        })
    },
  },
});

export const {
    registerUser
} = registerSlice.actions;

export default registerSlice.reducer;
