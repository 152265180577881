import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../../axios";
import LoadingSpinner from "../../components/LoadingSpinner";

function Roles() {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [role, setRole] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);


    const getUserRoles = useCallback(async () => {
        setLoading(true);
        instance.post(`/users/${id}/roles`).then(({ data }) => {
            setLoading(false);
            setRoles(data.roles);
        })
    }, [id])

    const handleCheck = (e) => {
        setRole(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const data = {
            roles: [
                role
            ], user_id: id
        }
        instance.post(`/roles`, data).then(({ data }) => {
            setIsSubmitting(false);
            console.log(data);
        }).catch((err) => {
            setIsSubmitting(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getUserRoles();
    }, [getUserRoles])
    return (
        <>
            {/* {
                loading ? <LoadingSpinner /> : (<>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Roles</h3>
                        </div>
                        <div className="card-body">
                            {
                                Radio buttons
                                roles.map((role, index) => (
                                    <div className="form-check" key={index}>
                                        Radio buttons
                                        <input className="form-check-input" type="radio" name="role" id={`role-${index}`} value={role.id} onChange={handleCheck} defaultChecked={role.checked} />
                                        <label className="form-check-label" htmlFor={`role-${index}`}>
                                            {role.name}
                                        </label>
                                    </div>
                                ))

                            }
                            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
                                {
                                    isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"
                                }
                            </button>
                        </div>
                    </div>
                </>)
            } */}
            {loading ? (
  <LoadingSpinner />
) : (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Roles</h3>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
      {roles.map((role, index) => (
        <div key={index} className="mb-3 flex items-center">
          <input
            className="form-radio h-4 w-4 text-primary focus:ring-primary border-gray-300"
            type="radio"
            name="role"
            id={`role-${index}`}
            value={role.id}
            onChange={handleCheck}
            checked={role.checked}
          />
          <label
            className="ml-2 block text-sm font-medium text-gray-700"
            htmlFor={`role-${index}`}
          >
            {role.name}
          </label>
        </div>
      ))}
      <button
        className="bg-primary text-white py-2 px-4 mt-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  </div>
)}

        </>
    )
}

export default Roles;