import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import instance from "../../axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Bar, Cell, PieChart, Pie, Legend, Tooltip, Label } from "recharts";
import { CSVLink } from "react-csv";
import { toPng } from 'html-to-image';
import FileSaver from "file-saver";


const graphData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
];

function SurveyReport() {

  const [responseData, setResponseData] = useState([]);
  const [radioData, setRadioData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const { slug } = useParams();
  const [survey, setSurvey] = useState({});
  const [surveyResponses, setSurveyResponses] = useState([]);
  const [data, setData] = useState([]);
  const [barChartCount, setBarChartCount] = useState(0);
  const [barChartData, setBarChartData] = useState([]);
  const [barCharts, setBarCharts] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [responseId, setResponseId] = useState(0);

  const [surveyData, setSurveyData] = useState([]);
  const [exportData, setExportData] = useState([]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const exportChart = (id) => {
    const chartContainer = document.getElementById(id);
    toPng(chartContainer, { cacheBust: true, })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'chart.png';
      });




    // toPng.toSvgDataURL(chartContainer)
    //   .then(function (dataUrl) {
    //     const link = document.createElement('a');
    //     link.download = 'chart.svg';
    //     link.href = dataUrl;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   });
  }

  const handleSteps = (step) => {
    if (step === 1) {
      setStep1(true);
      setStep2(false);
      setStep3(false);
    } else if (step === 2) {
      setStep1(false);
      setStep2(true);
      setStep3(false);
    } else {
      setStep1(false);
      setStep2(false);
      setStep3(true);
    }
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const handleExport = async (slug) => {
    const response = await instance.post(`/export-to-csv`, { slug });
    const data = response.data;
    setExportData(data);
    // console.log(data);
    setIsDownloading(true);
    // await instance.get(`/survey/${survey.survey_id}/export`).then((res) => {
    //   console.log(res.data);
    //   setExportData(res.data);
    // });
  };

  const exportToCsv = (slug) => {
    instance.post(`/export-to-csv`, { slug }).then((res) => {
      const blob = new Blob([res.data], { type: 'text/csv' });
      FileSaver.saveAs(blob, 'export.csv');
    });
  }

  const createLineChart = (title, data) => {
    return (
      <>
        <h5 className="mt-2">{title}</h5>
        <LineChart width={730} height={250} data={data}>
          <Line type="monotone" dataKey="pv" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="name" />
          <YAxis />
        </LineChart>
      </>
    );
  };

  const getSurveyReport = useCallback(async () => {
    setIsLoading(true);
    instance
      .get(`/survey-report/${slug}`)
      .then((res) => {
        // setSurveyResponses(res.data.responses);
        setSurvey(res.data.survey);
        setBarChartCount(res.data.bar_chart_data.length);
        setBarChartData(res.data.bar_chart_data);
        setSurveyData(res.data.answers);
        setQuestions(res.data.questions);
        setResponseData(res.data.responseData);
        setRadioData(res.data.radio_responses);
        setPartA(res.data.part_A);
        setPartB(res.data.part_B);
        // setExportData(res.data.export_data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  }, [slug]);

  const getResponse = (slug, id) => {
    setResponseId(id);
    instance.get(`/survey-report/${slug}/response/${id}`).then((res) => {
      setSurveyResponses(res.data.responses);
      setIsK10(false);
      setK10Ranking(0);
      setK10Total(0);
      setK10ColorCoding("");
    });
  }
  // /survey-report/{slug}/response
  useEffect(() => {
    getSurveyReport();
    setData(graphData);
  }, [getSurveyReport]);

  const [isK10, setIsK10] = useState(false);
  const [k10Ranking, setK10Ranking] = useState(0);
  const [K10Total, setK10Total] = useState(0);
  const [k10ColorCoding, setK10ColorCoding] = useState("");
  const [k10Message, setK10Message] = useState("Not Available");
  const [partA, setPartA] = useState([]);
  const [partB, setPartB] = useState([]);

  const handleQuestionChange = (question_id) => {
    // const question_id = e.target.value;
    const data = {
      question_id,
      slug,
      response_id: responseId,
    }

    instance.post(`/survey-report/${slug}/k10`, data).then((res) => {
      if (res.data.is_k10) {
        // console.log(res.data);
        setIsK10(true);
        setK10Ranking(res.data.ranking);
        setK10Total(res.data.total);
        setK10ColorCoding(res.data.color_coding);
      } else {
        setIsK10(false);
      }
    })
  }
  const ColorCode = ({ total }) => {
    const rating = ["green", "yellow", "orange", "red"];

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="mx-2" style={{ backgroundColor: total >= 10 && rating[0], width: "100%", height: "20px" }}></div>
        <div className="mx-2" style={{ backgroundColor: total >= 16 && rating[1], width: "100%", height: "20px" }}></div>
        <div className="mx-2" style={{ backgroundColor: total >= 22 && rating[2], width: "100%", height: "20px" }}></div>
        <div className="mx-2" style={{ backgroundColor: total >= 30 && rating[3], width: "100%", height: "20px" }}></div>
      </div>
    )
  }

  const randomHexColor = () => {
    // shades of blue
    const colors = ["#0000FF", "#0000CD", "#00008B", "#000080", "#191970", "#4169E1", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6", "#AFEEEE", "#00BFFF", "#1E90FF", "#6495ED", "#7B68EE", "#4169E1", "#0000CD", "#00008B", "#000080", "#191970", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6", "#AFEEEE", "#00BFFF", "#1E90FF", "#6495ED", "#7B68EE", "#4169E1", "#0000CD", "#00008B", "#000080", "#191970", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6", "#AFEEEE", "#00BFFF", "#1E90FF", "#6495ED", "#7B68EE", "#4169E1", "#0000CD", "#00008B", "#000080", "#191970", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6", "#AFEEEE", "#00BFFF", "#1E90FF", "#6495ED", "#7B68EE", "#4169E1", "#0000CD", "#00008B", "#000080", "#191970", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6", "#AFEEEE", "#00BFFF", "#1E90FF", "#6495ED", "#7B68EE", "#4169E1", "#0000CD", "#00008B", "#000080", "#191970", "#6495ED", "#B0C4DE", "#87CEFA", "#87CEEB", "#ADD8E6", "#B0E0E6"]
    // const colors = ['blue', 'red', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'black', 'gray', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'navy', 'maroon', 'aquamarine', 'turquoise', 'silver', 'limegreen', 'indigo', 'violet', 'tan', 'thistle', 'plum', 'orchid', 'moccasin', 'mistyrose', 'linen', 'lavender', 'ivory', 'honeydew', 'gold', 'ghostwhite', 'floralwhite', 'firebrick', 'darkviolet', 'darkturquoise', 'darkslategray', 'darkslateblue', 'darkseagreen', 'darkred', 'darkorchid', 'darkorange', 'darkolivegreen', 'darkmagenta', 'darkkhaki', 'darkgreen', 'darkgray', 'darkgoldenrod', 'darkcyan', 'darkblue', 'crimson', 'cornsilk', 'chocolate', 'chartreuse', 'burlywood', 'brown', 'blueviolet', 'blanchedalmond', 'bisque', 'beige', 'azure', 'antiquewhite', 'aliceblue', 'aqua', 'aquamarine', 'black', 'blue', 'brown', 'cyan', 'darkblue', 'darkcyan', 'darkgray', 'darkgreen', 'darkmagenta', 'darkolivegreen', 'darkorange', 'darkorchid', 'darkred', 'darksalmon', 'darkviolet', 'fuchsia', 'gold', 'green', 'indigo', 'lavender', 'lime', 'magenta', 'maroon', 'navy', 'olive', 'orange', 'pink', 'purple', 'red', 'silver', 'tan', 'teal', 'yellow']
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const k10TableBackgroundColor = (stressLevel) => {
    // console.log(stressLevel);
    const colors = ['green', 'yellow', 'orange', 'red'];
    switch (stressLevel) {
      case 'Low':
        return colors[0];
        break;
      case 'Moderate':
        return colors[1];
        break;
      case 'High':
        return colors[2];
        break;
      case 'Very High':
        return colors[3];
        break;
      case 'Extremely High':
        return colors[3];
        break;

      default:
        break;
    }
  };

  // const formatLabel = (value, entry) => {
  //   const percentage = `${(entry.percent * 100).toFixed(2)}%`;
  //   return `${entry.name} ${percentage}`;
  // }

  // const renderCustomizedLabel = (entry) => {
  //   return (
  //     <Label key={entry.name}
  //     position={"center"}
  //     formatter={formatLabel}
  //     />
  //   )
  // }

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }

  const [k10Summary, setK10Summary] = useState([]);
  const getK10summary = (slug) => {
    instance.post(`/summarize-k10`, { slug }).then((res) => {
      setK10Summary(res.data.data);
    })
  }

  // /^(1|2|3|4|5)(, (1|2|3|4|5)){9}$/
  const calculateK10 = (scores) => {
    // console.log(scores);
    const regex = /^(1|2|3|4|5)(, (1|2|3|4|5)){9}$/;
    // console.log(regex.test(scores));
    if (regex.test(scores)) {
      // convert string to array
      const scoreArray = scores.split(", ");
      // convert string to number
      const scoreNumber = scoreArray.map((score) => parseInt(score));
      // calculate total
      const total = scoreNumber.reduce((a, b) => a + b, 0);
      // calculate ranking
      const ranking = total >= 10 && total <= 15 ? 'Low' : total >= 16 && total <= 21 ? 'Moderate' : total >= 22 && total <= 29 ? 'Very High' : total >= 30 && total <= 50 ? 'Extreme' : 'No risk';
      // calculate color coding
      const color_coding = total >= 10 && total <= 15 ? "green" : total >= 16 && total <= 21 ? "yellow" : total >= 22 && total <= 29 ? "orange" : total >= 30 && total <= 50 ? "red" : "white";

      return `Kessler Score ${ranking} (${total})`;
    }
    return scores;
  }

  function timeStamp(userId) {
    // get first timestamp from answers
    console.log(userId);
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {/* <div
            className="d-flex justify-content-center align-items-center bg-light"
            style={{ height: "50px" }}
          >
            <Link
              className={`text-decoration-none mx-2 ${step1 && "fw-bold"}`}
              onClick={() => handleSteps(1)}
            >
              Responses
            </Link>
            <Link
              className={`text-decoration-none mx-2 ${step3 && "fw-bold"}`}
              onClick={() => {
                handleSteps(3)
                getK10summary(slug)
              }}
            >
              Kessler Psychological Distress (K-10)
            </Link>
            <Link
              className={`text-decoration-none mx-2 ${step2 && "fw-bold"}`}
              onClick={() => handleSteps(2)}
            >
              Summaries
            </Link>

            {
              !isDownloading && (
                <button className="btn btn-primary" onClick={() => exportToCsv(slug)}>
                  Export
                </button>)
            }
            {isDownloading && (
              <CSVLink data={exportData} filename="survey.csv" target="_blank"
                onClick={() => setIsDownloading(false)}
                className="btn btn-primary"
              >
                Save
              </CSVLink>
            )}
          </div> */}
          <div className="flex justify-center items-center bg-gray-100 h-16 px-4">
            <a
              href="#"
              className={`text-gray-800 no-underline font-bold mx-2 py-2 border-b-2 border-transparent hover:border-gray-800 ${step1 && "border-gray-800"}`}
              onClick={() => handleSteps(1)}
            >
              Responses
            </a>
            <a
              href="#"
              className={`text-gray-800 no-underline font-bold mx-2 py-2 border-b-2 border-transparent hover:border-gray-800 ${step3 && "border-gray-800"}`}
              onClick={() => {
                handleSteps(3);
                getK10summary(slug);
              }}
            >
              Kessler Psychological Distress (K-10)
            </a>
            <a
              href="#"
              className={`text-gray-800 no-underline font-bold mx-2 py-2 border-b-2 border-transparent hover:border-gray-800 ${step2 && "border-gray-800"}`}
              onClick={() => handleSteps(2)}
            >
              Summaries
            </a>
            <div className="ml-auto">
              {!isDownloading && (
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => exportToCsv(slug)}
                >
                  Export
                </button>
              )}
              {isDownloading && (
                <CSVLink
                  data={exportData}
                  filename="survey.csv"
                  target="_blank"
                  onClick={() => setIsDownloading(false)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
                >
                  Save
                </CSVLink>
              )}
            </div>
          </div>

          {step1 && (
            // <div>
            //   <div className="row">
            //     <div className="col-lg-4">
            //     </div>
            //     <div className="col-lg-8">
            //       <h2>{survey.title}</h2>
            //       <p>{survey.description}</p>
            //       <hr />
            //       {
            //         Object.keys(responseData).map((key, index) => (
            //           <div className="card my-2" key={index} style={
            //             {
            //               backgroundColor: randomHexColor()
            //             }
            //           }>
            //             <div className="card-body">
            //               <h5 className="card-title">{key}</h5>
            //               {
            //                 Object.keys(responseData[key]).map((item, index) => (
            //                   <div className="card my-2" key={index}>
            //                     <div className="card-body">
            //                       <p className="fw-bold"> {item}</p>
            //                       <p>
            //                         {
            //                           calculateK10(responseData[key][item])
            //                         }
            //                       </p>
            //                     </div>
            //                   </div>
            //                 ))
            //               }
            //             </div>
            //           </div>
            //         ))
            //       }
            //       {
            //         Object.keys(surveyResponses).map((key, index) => (
            //           <div className="card my-2" key={key}>
            //             <div className="card-body">
            //               <h5 className="card-title">{key}
            //               </h5>
            //               <div className="card-text">
            //                 {
            //                   surveyResponses[key]
            //                 }
            //               </div>
            //             </div>
            //           </div>
            //         ))
            //       }
            //     </div>
            //   </div>
            // </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3"></div>
              <div className="w-full lg:w-2/3 p-4">
                <h2 className="text-3xl font-bold mb-2">{survey.title}</h2>
                <p className="text-lg mb-4">{survey.description}</p>
                <hr className="my-4" />
                {
                  Object.keys(responseData).map((key, index) => (
                    <div className={`card my-2`} key={index}
                      style={
                        {
                          backgroundColor: randomHexColor()
                        }
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-xl font-bold mb-2 text-white">{key}</h5>
                        {
                          Object.keys(responseData[key]).map((item, index) => (
                            <div className="card my-2" key={index}>
                              <div className="card-body">
                                <p className="font-bold">{item}</p>
                                <p>{calculateK10(responseData[key][item])}</p>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
                {
                  Object.keys(surveyResponses).map((key, index) => (
                    <div className="card my-2" key={key}>
                      <div className="card-body">
                        <h5 className="card-title text-xl font-bold mb-2">{key}</h5>
                        <div className="card-text">{surveyResponses[key]}</div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          )}
          {step2 && (
            <div className="my-5">
              <h5 className="mt-2">
                <strong>SOCIO-DEMOGRAPHIC DATA </strong>
              </h5>
              {/* <table className="">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Response</th>
                    <th scope="col">Count</th>
                    <th scope="col">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.keys(partA).map((key, index) => (
                      <tr key={index}
                        style={
                          {
                            backgroundColor: randomHexColor(),
                            color: randomHexColor() === "#ffffff" ? "#000000" : "#ffffff"
                          }
                        }
                      >
                        <td>{key}</td>
                        <td style={{
                          padding: "0px"
                        }}>
                          {
                            Object.keys(partA[key]).length > 1 ? (
                              <table className=" m-0">
                                <tbody>
                                  {
                                    Object.keys(partA[key]).map((item, index) => (
                                      <tr key={index}>
                                        <td>{item}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : (
                              <div>
                                {
                                  Object.keys(partA[key]).map((item, index) => (
                                    <div key={index}>
                                      {item}
                                    </div>
                                  ))
                                }
                              </div>
                            )
                          }
                        </td>
                        <td
                          style={{
                            padding: "0px"
                          }}
                        >
                          {
                            Object.keys(partA[key]).length > 1 ? (
                              <table className=" m-0">
                                <tbody>
                                  {
                                    Object.keys(partA[key]).map((item, index) => (
                                      <tr key={index}>
                                        <td>{partA[key][item].count}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : (
                              <div>
                                {
                                  Object.keys(partA[key]).map((item, index) => (
                                    <div key={index}>
                                      {partA[key][item].count}
                                    </div>
                                  ))
                                }
                              </div>
                            )
                          }
                        </td>
                        <td
                          style={{
                            padding: "0px"
                          }}
                        >
                          {
                            Object.keys(partA[key]).length > 1 ? (
                              <table className=" m-0">
                                <tbody>
                                  {
                                    Object.keys(partA[key]).map((item, index) => (
                                      <tr key={index}>
                                        <td>{partA[key][item].percentage}%</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : (
                              <div>
                                {
                                  Object.keys(partA[key]).map((item, index) => (
                                    <div key={index}>
                                      {partA[key][item].percentage}%
                                    </div>
                                  ))
                                }
                              </div>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table> */}
              {/* <table className="w-full">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Response</th>
                    <th scope="col">Count</th>
                    <th scope="col">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(partA).map((key, index) => (
                    <tr
                      key={index}
                      className={`bg-blue-${index + 1}00 text-white`}
                    >
                      <td className="px-4 py-2">{key}</td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{item}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{item}</div>
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{partA[key][item].count}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{partA[key][item].count}</div>
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{partA[key][item].percentage}%</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{partA[key][item].percentage}%</div>
                            ))}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
              <table className="w-full">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Response</th>
                    <th scope="col">Count</th>
                    <th scope="col">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(partA).map((key, index) => (
                    <tr
                      key={index}
                      style={{ backgroundColor: randomHexColor() }}
                      className={`bg-blue-${index % 9 + 1}00 text-white`}
                    >
                      <td className="px-4 py-2">{key}</td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{item}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{item}</div>
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{partA[key][item].count}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{partA[key][item].count}</div>
                            ))}
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2">
                        {Object.keys(partA[key]).length > 1 ? (
                          <table className="m-0">
                            <tbody>
                              {Object.keys(partA[key]).map((item, index) => (
                                <tr key={index}>
                                  <td>{partA[key][item].percentage}%</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            {Object.keys(partA[key]).map((item, index) => (
                              <div key={index}>{partA[key][item].percentage}%</div>
                            ))}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>



              <div className="my-5">
                <h5 className="mt-2">
                  <strong>ASSESSMENT FOR FACTORS THAT MAY BE ASSOCIATED WITH PSYCHOLOGICAL DISTRESS </strong>
                </h5>
                {/* <table className="">
                  <thead>
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col">Response</th>
                      <th scope="col">Count</th>
                      <th scope="col">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(partB).map((key, index) => (
                        <tr key={index}
                          style={{
                            backgroundColor: randomHexColor(),
                            color: randomHexColor() === "#ffffff" ? "#000000" : "#ffffff"
                          }}
                        >
                          <td>{key}</td>
                          <td style={{
                            padding: "0px"
                          }}>
                            {
                              Object.keys(partB[key]).length > 1 ? (
                                <table className=" m-0">
                                  <tbody>
                                    {
                                      Object.keys(partB[key]).map((item, index) => (
                                        <tr key={index}>
                                          <td>{item}</td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              ) : (
                                <div>
                                  {
                                    Object.keys(partB[key]).map((item, index) => (
                                      <div key={index}>
                                        {item}
                                      </div>
                                    ))
                                  }
                                </div>
                              )
                            }
                          </td>
                          <td
                            style={{
                              padding: "0px"
                            }}
                          >
                            {
                              Object.keys(partB[key]).length > 1 ? (
                                <table className=" m-0">
                                  <tbody>
                                    {
                                      Object.keys(partB[key]).map((item, index) => (
                                        <tr key={index}>
                                          <td>{partB[key][item].count}</td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              ) : (
                                <div>
                                  {
                                    Object.keys(partB[key]).map((item, index) => (
                                      <div key={index}>
                                        {partB[key][item].count}
                                      </div>
                                    ))
                                  }
                                </div>
                              )
                            }
                          </td>
                          <td
                            style={{
                              padding: "0px"
                            }}
                          >
                            {
                              Object.keys(partB[key]).length > 1 ? (
                                <table className=" m-0">
                                  <tbody>
                                    {
                                      Object.keys(partB[key]).map((item, index) => (
                                        <tr key={index}>
                                          <td>{partB[key][item].percentage}%</td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              ) : (
                                <div>
                                  {
                                    Object.keys(partB[key]).map((item, index) => (
                                      <div key={index}>
                                        {partB[key][item].percentage}%
                                      </div>
                                    ))
                                  }
                                </div>
                              )
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table> */}
                <table className="w-full">
                  <thead>
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col">Response</th>
                      <th scope="col">Count</th>
                      <th scope="col">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(partB).map((key, index) => (
                      <tr key={index} className={`bg-blue-${index % 9 + 1}00 text-white`}
                        style={{
                          backgroundColor: randomHexColor()
                        }}
                      >
                        <td className="px-4 py-2">{key}</td>
                        <td className="px-4 py-2">
                          {Object.keys(partB[key]).length > 1 ? (
                            <table className="m-0">
                              <tbody>
                                {Object.keys(partB[key]).map((item, index) => (
                                  <tr key={index}>
                                    <td>{item}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>
                              {Object.keys(partB[key]).map((item, index) => (
                                <div key={index}>{item}</div>
                              ))}
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-2">
                          {Object.keys(partB[key]).length > 1 ? (
                            <table className="m-0">
                              <tbody>
                                {Object.keys(partB[key]).map((item, index) => (
                                  <tr key={index}>
                                    <td>{partB[key][item].count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>
                              {Object.keys(partB[key]).map((item, index) => (
                                <div key={index}>{partB[key][item].count}</div>
                              ))}
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-2">
                          {Object.keys(partB[key]).length > 1 ? (
                            <table className="m-0">
                              <tbody>
                                {Object.keys(partB[key]).map((item, index) => (
                                  <tr key={index}>
                                    <td>{partB[key][item].percentage}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>
                              {Object.keys(partB[key]).map((item, index) => (
                                <div key={index}>{partB[key][item].percentage}%</div>
                              ))}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
              {/* <div>
                {Object.keys(barChartData).map((key, index) => {
                  return (
                    <div key={index}>
                      <h5 className="mt-2">{key}</h5>
                      <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={1000}
                        height={250}
                        data={barChartData[key]}
                      >
                        <Line type="monotone" dataKey="amt" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis />
                      </LineChart>
                      </ResponsiveContainer>
                      <ResponsiveContainer width="100%" height="100%">
                      <pre>
                        {JSON.stringify(barChartData[key], null, 2)}
                      </pre>
                      <button className="btn btn-primary" onClick={() => exportChart(`chart-${index}`)}>Export</button>
                      <BarChart width={1000} height={500} data={barChartData[key]} margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 100,
                      }}>
                        <Bar dataKey="value">
                          {
                            barChartData[key].map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getRandomColor()} />
                            ))
                          }
                        </Bar>
                        <div id={`chart-${index}`}></div>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name"/>
                        <YAxis />
                        <XAxis dataKey="name" interval={0} tick={{ fontSize: 12, textAnchor: 'end', width: 100, wrapStyle: { lineHeight: '1.2' } }} />
                      </BarChart>
                      </ResponsiveContainer>
                      <PieChart width={1000} height={500}>
                        <Pie
                          data={barChartData[key]}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={200}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {
                            barChartData[key].map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getRandomColor()} />
                            ))
                          }
                        </Pie>
                        <Legend />
                        <Tooltip />
                      </PieChart>
                    </div>
                  );
                })}
              </div> */}

            </div>
          )}
          {
            step3 && (
              <div>
                {/* <h5 className="mt-2">Kessler Psychological Distress (K-10)</h5> */}
                {/* <div className="mt-4">
                  {
                    Object.keys(k10Summary).length > 0 ? (
                      <table className="">
                        <thead>
                          <tr>
                            <th>
                              Psychological Distress
                            </th>
                            <th>
                              Count
                            </th>
                            <th>
                              Percentage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            Object.keys(k10Summary).map((item, index) => (
                              <tr key={index}
                                style={{
                                  backgroundColor: k10TableBackgroundColor(item),
                                  color: "#fff"
                                }}
                              >
                                <td>{item}</td>
                                <td>{k10Summary[item].count}</td>
                                <td>{k10Summary[item].percentage}%</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    ) : (
                      <p>
                        No data
                      </p>
                    )
                  }
                </div> */}
                <div>
                  <h5 className="mt-2 text-center">Kessler Psychological Distress (K-10)</h5>
                  <div className="mt-4">
                    {Object.keys(k10Summary).length > 0 ? (
                      <table className="mx-auto border-collapse">
                        <thead>
                          <tr>
                            <th className="border border-gray-400 py-2 px-4"></th>
                            <th className="border border-gray-400 py-2 px-4">Psychological Distress</th>
                            <th className="border border-gray-400 py-2 px-4">Count</th>
                            <th className="border border-gray-400 py-2 px-4">Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(k10Summary).map((item, index) => (
                            <tr
                              key={index}
                              className={`text-black`}
                            >
                              <td className="border border-gray-400 py-2 px-4"
                              style={{
                                backgroundColor: k10TableBackgroundColor(item)
                              }}
                              ></td>
                              <td className="border border-gray-400 py-2 px-4">{item}</td>
                              <td className="border border-gray-400 py-2 px-4">{k10Summary[item].count}</td>
                              <td className="border border-gray-400 py-2 px-4">{k10Summary[item].percentage}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center">No data</p>
                    )}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      )}
    </>
  );
}

export default SurveyReport;
