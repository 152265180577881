import React from 'react'

function FormHeader({title}) {
  return (
    <h3 className='fw-bold'>
        {title}
    </h3>
  )
}

export default FormHeader