import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import instance from '../axios'

function Message() {
    const [inbox, setInbox] = useState([])
    const [messages, setMessages] = useState([])
    const [users, setUsers] = useState([])
    const [receiver, setReceiver] = useState(null)
    const [chatId, setChatId] = useState(null)
    const [message, setMessage] = useState('')

    const newChat = async (id) => {
        console.log(id)
        const { data } = await instance.post(`/chats/store`, { receiver_id: id })
        setInbox([...inbox, data.chat])
    }

    const fetchUsers = async () => {
        const { data } = await instance.get(`/users/chat-list`)
        setUsers(data.data)
    }

    const fetchMessages = async (id) => {
        setMessages([])
        const { data } = await instance.post(`/user-messages/fetch`, { user_id: id })
        setMessages(data.data)
    }

    const sendMessage = (e, receiver_id) => {
        e.preventDefault()
        instance.post(`/user-messages/create`, { receiver_id, message })
            .then(({ data }) => {
                setMessages([...messages, data.data])
                setMessage('')
            }
            )
    }

    const fetchChats = async () => {
        const { data } = await instance.get(`/chats`)
        setInbox(data.chats)
    }

    useEffect(() => {
        fetchUsers()
        // fetchChats()
    }, [])
    return (
        <>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-4">
                        <h3>Chat</h3>
                        <div className="list-group">
                            {users.map((user) => (
                                <Link className={`list-group-item list-group-item-action ${receiver == user.user_id ? 'bg-primary text-white' : ''}`} key={user.id} onClick={() => {
                                    fetchMessages(user.user_id)
                                    setReceiver(user.user_id)
                                }}>
                                    {user.name}
                                </Link>
                            ))}
                        </div>
                        <h3>Inbox</h3>
                        {inbox.map((inbox) => (
                            <Link className="card mb-3 text-decoration-none" key={inbox.chat_id}
                                onClick={() => {
                                    fetchMessages(inbox.chat_id)
                                    // setReceiver(inbox.receiver_id)
                                    setChatId(inbox.chat_id)
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title">{`${inbox.first_name} ${inbox.last_name}`}</h5>
                                </div>
                            </Link>
                        ))}
                    </div> */}
                    {/* <div className="col-md-4">
  <h3 className="text-lg font-medium mb-4">Chat</h3>
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="divide-y divide-gray-200">
      {users.map((user) => (
        <Link
          className={`flex items-center justify-between px-4 py-3 hover:bg-gray-100 ${
            receiver === user.user_id ? 'bg-primary text-white' : ''
          }`}
          key={user.id}
          onClick={() => {
            fetchMessages(user.user_id);
            setReceiver(user.user_id);
          }}
        >
          <span className="text-base text-gray-900">{user.name}</span>
          {receiver === user.user_id && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6.293 6.293a1 1 0 0 1 1.414 0L10 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 0-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </Link>
      ))}
    </div>
  </div>
</div> */}
                    <div className="col-md-4">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Chat</h3>
                        <div className="bg-white rounded-lg shadow">
                            {users.map((user) => (
                                <button
                                    className={`w-full text-left px-4 py-2 hover:bg-gray-100 ${receiver == user.user_id ? 'bg-primary text-white' : ''
                                        }`}
                                    key={user.id}
                                    onClick={() => {
                                        fetchMessages(user.user_id);
                                        setReceiver(user.user_id);
                                    }}
                                >
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://github.com/mdo.png"
                                                alt={user.name}
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-sm font-medium text-gray-900">{user.name}</div>
                                            {/* <div className="text-sm text-gray-500 truncate">{user.last_message}</div> */}
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>


                    <div className="col-md-8">
                        {
                            messages.length > 0 ? (
                                messages.map((message) => (
                                    <div className="mb-3" key={message.id}>
                                        {message.sender_id === receiver ? (
                                            <div className="flex justify-end items-start">
                                                <div className="bg-blue-500 text-white rounded-lg py-2 px-4 max-w-md">
                                                    <p className="text-sm">{message.message}</p>
                                                    <p className="text-xs text-gray-300 mt-1">
                                                        {new Date(message.created_at).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex justify-start items-start">
                                                <div className="bg-gray-200 text-gray-700 rounded-lg py-2 px-4 max-w-md">
                                                    <p className="text-sm">{message.message}</p>
                                                    <p className="text-xs text-gray-500 mt-1">
                                                        {new Date(message.created_at).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    // <div className="mb-3" key={message.id}>
                                    //     {
                                    //         message.sender_id === receiver ? (
                                    //             <div className="d-flex justify-content-end">
                                    //                 <div className="">
                                    //                     <h5 className="">{message.message}</h5>
                                    //                     <p className='text-small text-muted'>
                                    //                         {
                                    //                             new Date(message.created_at).toLocaleString()
                                    //                         }
                                    //                     </p>
                                    //                 </div>

                                    //                 <div>
                                    //                     <div class="message received">
                                    //                         <div class="message-content">
                                    //                             <p>
                                    //                                 {message.message}
                                    //                             </p>

                                    //                         </div>
                                    //                     </div>
                                    //                     <small
                                    //                         style={{ fontSize: '10px' }}
                                    //                         class="message-timestamp text-muted">{new Date(message.created_at).toLocaleString()}</small>
                                    //                 </div>
                                    //             </div>
                                    //         ) : (
                                    //             <div className="d-flex justify-content-start">
                                    //                 <div className="">
                                    //                     <h5 className="">{message.message}</h5>
                                    //                     <p className='text-small text-muted'>
                                    //                         {
                                    //                             new Date(message.created_at).toLocaleString()
                                    //                         }
                                    //                     </p>
                                    //                 </div>
                                    //                 <div class="message received">
                                    //                     <div class="message-content">
                                    //                         <p>Hey, I'm good. How about you?</p>
                                    //                     </div>
                                    //                 </div>
                                    //                 <div>
                                    //                     <div class="message">
                                    //                         <div class="message-content">
                                    //                             <p>
                                    //                                 {message.message}
                                    //                             </p>

                                    //                         </div>
                                    //                         <div class="message-icon">
                                    //                         <i class="bi bi-chat"></i>
                                    //                     </div>
                                    //                     </div>
                                    //                     <small
                                    //                         style={{ fontSize: '10px' }}
                                    //                         class="message-timestamp text-muted">{new Date(message.created_at).toLocaleString()}</small>
                                    //                 </div>
                                    //             </div>
                                    //         )
                                    //     }
                                    // </div>
                                ))
                            ) : (

                                <div className="bg-white rounded-lg shadow-lg p-4">
                                    <h5 className="text-lg font-medium text-gray-900">No Messages</h5>
                                    <p className="text-gray-500 mt-2">Start a conversation to begin messaging.</p>
                                </div>

                            )
                        }
                        {/* New message */}
                        {/* <form onSubmit={
                            (e) => {
                                sendMessage(e, receiver)
                            }
                        }>
                            <div className="form-group mb-3">
                                <textarea className="form-control" rows="3"
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={receiver ? false : true}
                            >Send</button>
                        </form> */}
                        {/* <form onSubmit={(e) => sendMessage(e, receiver)} className="mb-3 my-4">
  <div className="mb-3">
    <textarea
      className="form-textarea block w-full px-4 py-3 rounded-lg shadow-sm focus:ring-primary focus:border-primary"
      rows="3"
      onChange={(e) => setMessage(e.target.value)}
      value={message}
      placeholder="Enter your message..."
      required
    ></textarea>
  </div>
  <button
    type="submit"
    className={`bg-primary text-white px-4 py-2 rounded-md shadow-sm ${
      receiver ? 'hover:bg-primary-dark' : 'opacity-50 cursor-not-allowed'
    }`}
    disabled={!receiver}
  >
    Send
  </button>
</form> */}
                        <form onSubmit={e => sendMessage(e, receiver)} className="flex flex-col space-y-4 my-5">
                            <div className="flex flex-col space-y-1">
                                <label htmlFor="message" className="font-semibold text-gray-700">Message</label>
                                <textarea
                                    id="message"
                                    className="form-textarea block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    rows="3"
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                className={`btn btn-primary ${receiver ? '' : 'opacity-50 cursor-not-allowed'}`}
                                disabled={receiver ? false : true}
                            >Send</button>
                        </form>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Message