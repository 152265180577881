import React from "react";

function MultiGrid({ headers, options, value, setValue, onChange }) {
    // const onChange = (e) => {
    //     setValue({ ...value, [e.target.name]: e.target.value });
    // };
  return (
    <div>
      <table className="table table-borderless table-responsive">
        <thead>
          <tr>
            <th></th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {options.map((option, index) => (
            <tr key={index}>
              <td>{option}</td>
              {headers.map((header, index) => (
                <td key={index}>
                  <input type="radio" name={option.split(" ").join("-")} onChange={onChange} value={header} required/>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MultiGrid;
