import React from "react";
import { useRouteError, Link } from "react-router-dom";


function NotFound() {
  const error = useRouteError();

  console.log(error);
  return (
    <div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="error-template">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div className="error-details my-2">
            Sorry, an error has occured, Requested page not found!
          </div>
          <div className="error-actions">
            <Link to="/" className="btn btn-primary btn-sm">
              <i className="bi bi-house"></i>{" "}
              Back to Home
            </Link>
            <Link href="/" className="btn btn-default btn-sm">
              <i className="bi bi-envelope"></i>{" "}
              Contact Support
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
