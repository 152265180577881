import React from "react";

function DashboardSummaryCard({ title, value }) {
  return (
    // <div className="col-3 d-flex justify-content-around align-items-center shadow-lg rounded py-4 px-2 mx-4">
    //   <div
    //     className="bg-info rounded d-flex justify-content-center align-items-center"
    //     style={{ width: "80px", height: "80px" }}
    //   >
    //     <i
    //       className="bi bi-info-circle-fill text-white"
    //       style={{ fontSize: "50px" }}
    //     ></i>
    //   </div>
    //   <div>
    //     <h3 className="fw-bold">{title}</h3>
    //     <p className="text-muted">{value}</p>
    //   </div>
    // </div>
    //   <div className="flex items-center shadow-lg rounded py-4 px-2 mx-4">
    //   <div className="bg-info rounded-full flex justify-center items-center w-20 h-20">
    //     <i className="bi bi-info-circle-fill text-white text-4xl"></i>
    //   </div>
    //   <div className="ml-4">
    //     <h3 className="font-bold text-xl">{title}</h3>
    //     <p className="text-gray-500">{value}</p>
    //   </div>
    // </div>
    //   <div className="flex flex-col md:flex-row items-center shadow-lg rounded py-4 px-2 mx-4">
    //   <div className="bg-info rounded-full flex justify-center items-center w-16 h-16 md:w-20 md:h-20">
    //     <i className="bi bi-info-circle-fill text-white text-3xl md:text-4xl"></i>
    //   </div>
    //   <div className="mt-4 md:mt-0 md:ml-4">
    //     <h3 className="font-bold text-lg md:text-xl">{title}</h3>
    //     <p className="text-gray-500 text-sm md:text-base">{value}</p>
    //   </div>
    // </div>
    <div className="w-64 h-64 flex flex-col items-center justify-center shadow-lg rounded p-4">
      <div className="text-5xl text-center text-blue-500">
        <i className="bi bi-info-circle-fill"></i>
      </div>
      <div className="text-gray-500 text-center mt-2">{title}</div>
      <div className="text-4xl text-center mt-2">{value}</div>
    </div>

  );
}

export default DashboardSummaryCard;
