import React from "react";

function KaiserTool({ headers, options, value, setValue, onChange }) {
  return (
    <div>
      <table className="table table-borderless table-responsive">
        <thead>
          <tr>
            <th></th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {options.map((option, index) => (
            <tr key={index}>
              <td>{option}</td>
              {headers.map((header, index) => (
                <td key={index}>
                  <input type="radio" name={option.split(" ").join("-")} onChange={onChange} value={header} required/>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default KaiserTool;
